import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../components/ThemeCMBP/Partials/Breadcrumb';

//i18n
import { withNamespaces } from 'react-i18next';

class CGU extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    togglemodal = () => {
        this.setState(prevState => ({}));
    };

    render() {
        return (
            <React.Fragment>
                <div className='page-content'>
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs title={this.props.t('dashboard')} breadcrumbItem={this.props.t('cgu')} />

                        <Row>
                            <Col lg='12'>
                                <Card>
                                    <CardBody>
                                        <h1>CONDITIONS GENERALES D'UTILISATION (CGU)</h1>
                                        <p>
                                            Le Client reconna&icirc;t avoir préalablement pris connaissance des
                                            conditions générales d'utilisation (CGU) applicables à la date d'activation
                                            de son compte et les accepte. Les droits d'utilisation de CheckmyBP sont
                                            régis par les CGU. CheckmyBP se réserve le droit de mettre à jour les CGU.
                                        </p>
                                        <p>
                                            <strong>Cela exposé, il a été convenu et arrêté ce qui suit :</strong>
                                        </p>
                                        <br />
                                        <h2>Article 1. – Objet et description du service</h2>
                                        <p>
                                            Les présentes Conditions Générales d'Utilisation (&laquo; CGU &raquo;)
                                            définissent les termes et conditions applicables par le &laquo; Client
                                            &raquo; de l'utilisation des logiciels édités par CHECKMYBP (les &laquo;
                                            Progiciels &raquo;) et de son site internet (Site).
                                        </p>
                                        <p>
                                            Les Progiciels, permettent à un porteur de projet, un entrepreneur ou plus
                                            généralement de toute personne :
                                        </p>
                                        <p>
                                            - de disposer d'informations contextuelles sur les données financières de
                                            base abordés par les Progiciels,
                                        </p>
                                        <p>
                                            - obtenir une pré-validation de la cohérence des prévisions financières
                                            établies par le Client, selon les principes d'analyse retenus par CHECHMYBP.
                                        </p>
                                        <p>
                                            Dès qu'il accède au Site et active son compte Client, le Client
                                            reconna&icirc;t avoir pris connaissance des CGU accessibles sur la page
                                            d'accueil du Site de CHECKMYBP, et s'engage à en respecter les termes.
                                        </p>
                                        <p>
                                            La lecture et l'acceptation sans réserve des présentes CGU par le Client
                                            sont impératives et conditionnent l'accès, la navigation et l'utilisation
                                            des Progiciels.
                                        </p>
                                        <p>
                                            CHECKMYBP concède au Client, dans les conditions et limites stipulées aux
                                            présentes, un droit d'utilisation non exclusif et non transférable desdits
                                            Progiciels.
                                        </p>
                                        <br />
                                        <h2>Article 2. – Conditions d'utilisation</h2>
                                        <p>Le Client est l'utilisateur principal des Progiciels.</p>
                                        <p>
                                            Le Client ne pourra faire bénéficier de l'accès aux Progiciels, à quelque
                                            titre et sous quelque forme que ce soit, un tiers étranger aux présentes
                                            CGU.
                                        </p>
                                        <p>
                                            Les Progiciels mis à disposition en exécution des présentes CGU ne peuvent
                                            être utilisés qu'à partir du site Checkmybp.fr
                                        </p>
                                        <p>
                                            En conséquence, et sauf autorisation écrite et préalable de CHECKMYBP, le
                                            Client s'interdit formellement de les reproduire et/ou installer, en tout ou
                                            partie, par tout moyen et sous toute forme, de façon permanente ou
                                            provisoire, sur tout autre équipement lui appartenant ou non.
                                        </p>
                                        <p>
                                            Les Progiciels mis à disposition du Client par CHECKMYBP demeurent la
                                            propriété entière et exclusive de CHECKMYBP, ainsi que toutes modifications
                                            ou améliorations qui pourraient y être apportées ultérieurement.
                                        </p>
                                        <p>
                                            CHECKMYBP se réserve le droit exclusif d'intervenir sur les Progiciels mis à
                                            disposition pour leur permettre d'être utilisés conformément à leur
                                            destination et notamment pour en corriger les éventuelles erreurs. Le Client
                                            s'interdit en conséquence formellement d'intervenir ou de faire intervenir
                                            un tiers sur ces derniers. Le Client n'a pas l'autorisation de décompiler ou
                                            d'accéder aux codes-source, de faire décompiler ou permettre l'accès aux
                                            codes-sources à un tiers, des Progiciels. Le Client s'interdit de procéder à
                                            des opérations d'ingénierie inverse des Progiciels objets du présent
                                            contrat.
                                        </p>
                                        <p>
                                            CHECKMYBP se réserve également le droit de modifier à tout moment les
                                            fonctionnalités des Progiciels, sans que le Client ne puisse s'y opposer.
                                        </p>
                                        <p>
                                            Il appartient au Client d'arrêter les dispositions permettant une
                                            utilisation satisfaisante des Progiciels. Le Client est en outre responsable
                                            de la protection des données enregistrées au niveau de son système
                                            d'informations le cas échéant.
                                        </p>
                                        <p>
                                            Le Client s'interdit d'octroyer des sous-licences des Progiciels sans
                                            l'accord écrit préalable de CHECKMYBP.
                                        </p>
                                        <p>
                                            Les matériels et logiciels permettant l'accès aux Progiciels sont à la
                                            charge exclusive du Client, au même titre que les frais (notamment de
                                            télécommunications) induits par leur utilisation.
                                        </p>
                                        <p>
                                            CHECKMYBP s'engage à faire ses meilleurs efforts pour assurer l'accès et la
                                            disponibilité des Progiciels et en limiter les perturbations en cas de
                                            maintenance ou mises à jour.
                                        </p>
                                        <p>
                                            CHECKMYBP sera libre d'effectuer tout complément ou amélioration de ses
                                            Progiciels, et plus généralement toute modification destinée à en améliorer
                                            l'exécution.
                                        </p>
                                        <p>
                                            Les CGU peuvent être modifiées à tout moment par CHECKMYBP. Les nouvelles
                                            CGU entreront en vigueur dès leur publication sur le Site de CHECKMYBP.
                                        </p>
                                        <br />
                                        <h2>Article 3. – Droits de propriété intellectuelle</h2>
                                        <p>
                                            CHECKMYBP garantit au Client qu'il détient l'intégralité des droits lui
                                            permettant de proposer l'utilisation au Client de ses Progiciels.
                                        </p>
                                        <p>
                                            Le Client s'engage à ne pas porter atteinte directement, indirectement ou
                                            par l'intermédiaire de tiers auxquels il serait associé, au droit de
                                            propriété littéraire et artistique de CHECKMYBP. En conséquence, le Client
                                            prendra toutes les mesures nécessaires à la protection desdits droits. A ce
                                            titre, il maintiendra notamment toutes les mentions de propriété portées sur
                                            les éléments constitutifs des Progiciels. Le Client s'engage à prendre à
                                            l'égard de son personnel et de toutes personnes extérieures qui auraient
                                            accès aux Progiciels, toutes les mesures nécessaires pour assurer le secret,
                                            la confidentialité et le respect du droit de propriété littéraire et
                                            artistique sur lesdits Progiciels.
                                        </p>
                                        <p>
                                            Le Client s'interdit toute utilisation des Progiciels non explicitement
                                            autorisée par les présentes. Toute extension du droit d'utilisation non
                                            prévue aux présentes sera soumise à l'autorisation écrite préalable de
                                            CHECKMYBP et devra faire l'objet d'un avenant. Au cas o&ugrave; les
                                            conditions d'utilisation auraient été transgressées, outre que tous les
                                            autres recours que CHECKMYBP pourrait avoir, le Client devra à CHECKMYBP une
                                            somme égale aux redevances qu'auraient d&ucirc; verser à CHECKMYBP toute
                                            tierce personne ayant utilisé les Progiciels, lorsque la responsabilité du
                                            Client sera engagée.
                                        </p>
                                        <p>
                                            Le Client s'engage à ne pas développer ou commercialiser les Progiciels
                                            objets des présentes CGU ou des produits susceptibles de le concurrencer. Il
                                            s'interdit d'utiliser les spécifications des Progiciels pour créer ou
                                            permettre la création d'un programme ayant la même destination.
                                        </p>
                                        <p>
                                            La signature du Contrat n'implique pas la cession à l'autre Partie des
                                            droits de propriété intellectuelle détenus par l'une des Parties avant la
                                            conclusion du présent Contrat, ni la restriction des droits existants.
                                        </p>
                                        <br />
                                        <h2>Article 4. – Responsabilité</h2>
                                        <p>
                                            Afin de pouvoir utiliser les Progiciels, le Client doit disposer d'un Compte
                                            Client et s'authentifier au moyen des Identifiants personnels de ce Compte.
                                            Il devra renseigner une adresse email valide et un mot de passe strictement
                                            personnel, sous sa responsabilité exclusive, ainsi que ses coordonnées, nom
                                            de son projet (si l'entreprise n'est déjà pas créée) ou de son entreprise.
                                            Il s'interdit de les communiquer à tout tiers.
                                        </p>
                                        <p>
                                            Il recevra alors à l'adresse mail indiquée un email de confirmation de
                                            création d'un Compte Client . Il recevra dans ce mail un lien hypertexte
                                            &laquo; d'activation &raquo; activant son Compte client. Tant que le Compte
                                            Client n'est pas activé, le Client ne pourra pas accéder aux Progiciels.
                                        </p>
                                        <p>
                                            Les Identifiants sont strictement personnels et confidentiels à chaque
                                            Client. Il s'interdit de les communiquer à des tiers et s'engage à mettre
                                            tout en &oelig;uvre pour maintenir leur confidentialité. Tout accès par son
                                            compte Client aux Progiciels, sera de la responsabilité de ce Client, sans
                                            recours possible contre CHECKMYBP.
                                        </p>
                                        <p>
                                            En cas de non activation, perte de mot de passe, le Client pourra contacter
                                            un mail CHECKMYBP à l'adresse <strong>info@checkmybp.fr</strong>
                                        </p>
                                        <p>
                                            CHECHMYBP lui indiquera alors la marche à suivre pour réactiver son compte.
                                        </p>
                                        <p>
                                            En cas de désactivation du compte Client, suite au non-respect des
                                            obligations du Client ou l'expiration de la durée d'utilisation des
                                            Progiciels, ce dernier ne pourra se retourner contre CHECKMYBP ; CHECKMYBP
                                            ne sera pas tenu de le rembourser , y compris s &lsquo;il souhaite de
                                            nouveau accéder aux Progiciels après l'expiration de la durée d'utilisation
                                            des Progiciels. Il devra alors réactiver un nouveau compte Client.
                                        </p>
                                        <p>
                                            CHECKMYBP ne pourra être tenu responsable des préjudices subis par le Client
                                            du fait de l'utilisation de ses Progiciels.
                                        </p>
                                        <p>
                                            Le Client utilise les Progiciels et les résultats obtenus par sa mise en
                                            &oelig;uvre sous sa responsabilité exclusive, sans recours possible contre
                                            CHECKMYBP. Notamment, la responsabilité de CHECKMYBP ne saurait être engagée
                                            à raison d'erreurs, quelle qu'en soit la cause, dans les résultats obtenus
                                            ou transmis, qu'il appartient au Client de vérifier.
                                        </p>
                                        <p>
                                            Les informations renseignées par le Client restent sous son entière
                                            responsabilité. Les critères de validation de cohérence des prévisions
                                            financières retenus par CHECKMYBP, n'expriment qu'une appréciation
                                            subjective de cette dernière. CHECKMYBP ne pourra être tenue responsable de
                                            la non-obtention de financements par le Client ou de remise en cause de la
                                            pertinence d'analyse fournie par les Progiciels. Chaque intervenant tiers
                                            analysant les données renseignées par le Client, garde sa propre analyse sur
                                            la cohérence du business-plan du Client et donc son libre arbitre.
                                        </p>
                                        <p>
                                            CHECKMYBP ne saurait de même être tenue responsable de la destruction
                                            accidentelle des données du Client enregistrées dans son système
                                            d'informations.
                                        </p>
                                        <p>
                                            En toute hypothèse, la responsabilité de CHECKMYBP est limitée à un montant
                                            égal à deux fois le prix payé par le Client pour utiliser les Progiciels.
                                        </p>
                                        <br />
                                        <h2>Article 5. – Conditions financières</h2>
                                        <p>
                                            L'utilisation des Progiciels est consentie aux conditions de prix
                                            mentionnées ci-dessous : 100 (cent) euros HT par compte Client, payable
                                            d'avance avant l'accès aux Progiciels. Ce prix s'entend par entité juridique
                                            utilisatrice des Progiciels. Chaque entité juridique est associée à un
                                            compte Client distinct. Un client qui voudrait valider plusieurs
                                            business-plans relatifs à des entités juridiques distinctes de son groupe,
                                            devra activer autant de compte utilisateurs, et donc payer autant de fois
                                            100 HT que de comptes activés.
                                        </p>
                                        <p>
                                            Le paiement s'effectue en ligne, via une interface de paiement sécurisée. La
                                            mise à disposition des Progiciels est subordonnée à la réception préalable
                                            du paiement par CHECKMYBP.
                                        </p>
                                        <br />
                                        <h2>Article 6. – Durée</h2>
                                        <p>
                                            L'utilisation des Progiciels est effective pour une période maximale de 6
                                            (six) mois dès activation du compte Client. Le Client peut alors modifier
                                            autant de fois qu'il le souhaite ses prévisions financières, jusqu'à la
                                            validation de leur cohérence souhaitée. Au-delà du sixième mois plus un
                                            jour, l'ensemble des données liées à ses prévisions sont effacées. Le Client
                                            ne peut alors accéder aux Progiciels. Le cas échéant, il devra alors de
                                            nouveau réactiver son compte Client s'il souhaite utiliser de nouveau les
                                            Progiciels.
                                        </p>
                                        <br />
                                        <h2>Article 7. – Résiliation</h2>
                                        <p>
                                            Le Contrat pourra être résilié de plein droit et sans formalités par
                                            CHECKMYBP à tout moment en cas de manquement par le Client à l'une
                                            quelconque de ses obligations aux termes des CGU. Le Client peut également
                                            résilié son compte Client. CHECKMYBP n'effectuera alors pas de remboursement
                                            du paiement effectué par ce dernier.
                                        </p>
                                        <p>
                                            En outre, le Client s'interdit notamment de : - enfreindre les exigences,
                                            procédures, règles ou règlementations des réseaux connectés au Site ou aux
                                            Progiciels ; - tenter de porter atteinte à tout Client ou prospect de
                                            CHECKMYBP, hébergeur ou réseau, ce qui comprend, sans exhaustivité aucune,
                                            le fait, volontairement ou par négligence, d'exposer le Site ou les
                                            Progiciels à un virus, de créer une saturation ou encore d'inonder le
                                            serveur ; - tenter d'accéder aux données qui ne sont pas destinées au Client
                                            ou d'entrer dans un serveur/un compte auquel le Client n'est pas autorisé à
                                            avoir accès ; - tenter de sonder, scruter ou de tester la vulnérabilité d'un
                                            système ou d'un réseau, ou encore d'enfreindre les mesures de sécurité ou
                                            d'authentification sans en avoir reçu l'autorisation ; - usurper l'identité
                                            ou les Identifiants d'une autre personne/Client.
                                        </p>
                                        <br />
                                        <h2>Article 8. – Confidentialité et traitement des données</h2>
                                        <p>
                                            CHECKMYBP s'engage à prendre toutes les précautions nécessaires pour
                                            maintenir la confidentialité des informations de toute nature transmises par
                                            le Client, dont CHECKMYBP aurait eu connaissance à l'occasion de l'exécution
                                            des présentes CGU ; ces précautions devant être au moins équivalentes à
                                            celles prises par elle pour assurer la confidentialité de ses propres
                                            informations confidentielles.
                                        </p>
                                        <p>
                                            Le Client autorise CHECKMYBP à publier un communiqué de presse sur son
                                            utilisation des Progiciels, ainsi que la publication de son logo sur les
                                            sites internet de CHECKMYBP, sauf avis contraire écrit du Client.
                                        </p>
                                        <p>
                                            La transparence sur la gestion des données personnelles est une valeur
                                            essentielle pour CHECKMYBP. Nous accordons la plus grande importance au
                                            respect de la vie privée et en ce sens, nous appliquons les dispositions de
                                            la loi informatique et liberté no 78-17 du 6 janvier 1978 dans sa version en
                                            vigueur et du règlement (UE) no 2016/679 du 27 avril 2016 relatif à la
                                            protection des personnes physiques à l'égard du traitement des données à
                                            caractère personnel et à la libre circulation de ces Données Personnelles
                                            (le RGPD). Nous nous engageons à ne collecter que les données strictement
                                            nécessaires pendant l'utilisation de nos Progiciels.
                                        </p>
                                        <p>
                                            Concernant les cookies, nous devrons les utiliser pour l'ouverture et la
                                            gestion de votre compte Client.
                                        </p>
                                        <p>
                                            Vous bénéficiez des droits suivants en nous contactant en précisant l'objet
                                            de votre demande et en y joignant une copie de votre pièce d'identité à
                                            l'adresse suivante <strong>info@checkmybp.fr</strong> : Droit d'accès
                                            relativement à l'ensemble des données vous concernant faisant l'objet d'un
                                            traitement par nos soins ; Droit de rectification en cas de données erronées
                                            ;Droit à l'effacement ;Droit d'opposition (vous pouvez vous opposer à ce que
                                            nous traitions les données vous concernant sauf si nous sommes tenus de le
                                            faire dans le cadre de la relation contractuelle que nous entretenons avec
                                            vous, pour d'éventuelles actions en justice et s'il existe des motifs
                                            impérieux et légitimes à ce que nous continuions de traiter vos données) ;
                                            Droit à la portabilité de vos données ; Droit à la limitation du traitement
                                            de vos données conformément à l'article 18 du RGPD ;Droit de communication
                                            d'instructions sur le traitement des données en cas de décès.
                                        </p>
                                        <br />
                                        <h2>Article 9. – Modification</h2>
                                        <p>
                                            Les dispositions des présentes CGU expriment l'intégralité de l'accord
                                            conclu entre CHECKMYBP et le Client. Elles prévalent sur toute propositions,
                                            échanges de lettres antérieurs à sa signature, ainsi que sur toutes autres
                                            dispositions figurant sur les documents commerciaux de toute nature
                                            (conditions générales, factures, &hellip;) émanant de CHECKMYBP.
                                        </p>
                                        <p>
                                            Il est formellement convenu que toute tolérance ou renonciation de CHECKMYBP
                                            dans l'application de tout ou partie des engagements prévus aux présentes
                                            CGU, quelles qu'en aient pu être la fréquence et la durée, ne saurait valoir
                                            modification des présentes CGU, ni générer un droit quelconque pour le
                                            Client.
                                        </p>
                                        <br />
                                        <h2>Article 10. – Incessibilité</h2>
                                        <p>
                                            Sauf autorisation préalable et écrite de CHECKMYBP, la présente acceptation
                                            des CGU par le Client, ne pourra faire l'objet d'une cession totale ou
                                            partielle du fait du Client.
                                        </p>
                                        <br />
                                        <h2>Article 11. – Nullité</h2>
                                        <p>
                                            La nullité ou l'inapplicabilité de l'une quelconque des stipulations des
                                            présentes CGU n'emportera pas la nullité des autres stipulations qui
                                            conserveront toute leur force et leur portée.
                                        </p>
                                        <br />
                                        <h2>Article 12. – Droit applicable – Attribution de compétence</h2>
                                        <p>
                                            De convention expresse entre CHECKMYBP et le Client, le présent contrat est
                                            soumis au droit français, à l'exclusion de toute autre législation.
                                        </p>
                                        <p>
                                            Il est rédigé en langue française. Dans le cas o&ugrave; il serait traduit
                                            en une ou plusieurs langues, seul le texte français ferait foi.
                                        </p>
                                        <p>
                                            En cas de litige de toute nature, compétence expresse est attribuée au
                                            Tribunal de Commerce de PARIS.
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(CGU);

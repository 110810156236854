import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

//i18n
import { withNamespaces } from 'react-i18next';

class viewBusinessPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            code: null,
            error: null,
            isLoading: true,
            paymentFailed: false,
            paymentSuccess: false,
            paymentCancelled: false
        };
    }

    componentDidMount() {
        this.getAllParams(window.location.search);
    }

    getAllParams(request) {
        let data = [];
        request = request.replace(/\?/g, '');
        const params = request.split('&');

        var itemsProcessed = 0;

        function asyncFunction(item, cb) {
            setTimeout(() => {
                cb();
            }, 100);
        }

        params.forEach((item, index, array) => {
            asyncFunction(item, () => {
                itemsProcessed++;
                let values = item.split('=');
                data[values[0].toLowerCase()] = values[1];
                this.setState({ data: data });
                if (itemsProcessed === array.length) {
                    this.callback();
                }
            });
        });
    }

    callback() {
        if (this.state.data.erreur === '00000') {
            this.setState({ isLoading: false, paymentSuccess: true, paymentCancelled: false, paymentFailed: false });
        } else if (this.state.data.erreur === '00001') {
            this.setState({ isLoading: false, paymentSuccess: false, paymentCancelled: true, paymentFailed: false });
        } else {
            this.setState({
                isLoading: false,
                paymentSuccess: false,
                paymentCancelled: false,
                paymentFailed: true,
                error: this.state.data.erreur
            });
        }
    }

    render() {
        const { isLoading, data, paymentFailed, paymentSuccess, paymentCancelled } = this.state;
        if (isLoading) {
            return (
                <div>
                    <p>Chargement...</p>
                </div>
            );
        } else if (paymentFailed) {
            return (
                <Redirect
                    to={{
                        pathname: '/business-plan/add-key',
                        message: 'Un problème est survenu lors de votre tentative de paiment. Code erreur :',
                        state: { data: data }
                    }}
                />
            );
        } else if (paymentCancelled) {
            return (
                <Redirect
                    to={{
                        pathname: '/business-plan/add-key',
                        message: "Votre achat a été annulé et vous n'avez pas été débité",
                        state: { data: data }
                    }}
                />
            );
        } else if (paymentSuccess) {
            return (
                <Redirect
                    to={{
                        pathname: '/business-plan/add-key',
                        message: 'Votre paiement a bien été effectué, nous vous avons crédité vos clés.',
                        state: { data: data }
                    }}
                />
            );
        } else {
            console.log(data);
            return <React.Fragment></React.Fragment>;
        }
    }
}

viewBusinessPlan.propTypes = {
    match: PropTypes.object
};

export default withNamespaces()(viewBusinessPlan);

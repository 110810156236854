import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import { Alert, Card, CardBody, CardTitle, CardSubtitle, Button, Container, Row, Col } from 'reactstrap';
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';

import Breadcrumbs from '../../components/ThemeCMBP/Partials/Breadcrumb';
import { initBP } from '../../helpers/businessPlan/businessPlan';
import { axiosInstance } from '../../helpers/axios';

class keyBusinessPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bp: null,
            bp_name: null,
            bp_type: null,
            isLoading: true,
            errorMessage: null,
            redirect: false
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem('initBP'))) {
            const initBP = JSON.parse(localStorage.getItem('initBP'));
            this.setState({ bp_name: initBP.name });
        }
        axiosInstance
            .get(process.env.REACT_APP_API_URL + '/business-plan/types')
            .then(res => {
                console.log(res.data);
                this.setState({ bp_type: res.data, isLoading: false });
            })
            .catch(err => {
                console.log(err);
            });
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        if (values.innovant) {
            values.innovant = 1;
        } else {
            values.innovant = 0;
        }
        if (values.project_created) {
            values.project_created = 1
        } else {
            values.project_created = 0
        }
        initBP(values);
        this.setState({ redirect: true });
    }

    render() {
        const { redirect, bp_type } = this.state;

        if (redirect) {
            return <Redirect to='/business-plan/add-key' />;
        }

        if (this.state.isLoading) {
            return (
                <div>
                    <b>Chargement en cours</b>
                </div>
            );
        } else {
            return (
                <React.Fragment>
                    <div className='page-content'>
                        <Container fluid>
                            <Breadcrumbs
                                title={this.props.t('Mon Business Plan')}
                                breadcrumbItem={this.props.t('01 - Initialisation')}
                            />

                            <Row>
                                <Col lg='12'>
                                    <AvForm className='form-horizontal' onValidSubmit={this.handleValidSubmit}>
                                        <Card>
                                            <CardBody>
                                                <CardTitle>Initialisation du business plan</CardTitle>
                                                <CardSubtitle className='mb-3'>
                                                    Veuillez renseigner les informations de base pour pouvoir initier le
                                                    lancement de votre business plan.
                                                </CardSubtitle>
                                                <br />

                                                {this.props.error && this.props.error ? (
                                                    <Alert color='danger'>{this.props.error}</Alert>
                                                ) : null}

                                                <div className='form-group row'>
                                                    <label
                                                        htmlFor='example-text-input'
                                                        className='col-md-2 col-form-label'
                                                    >
                                                        Nom de votre business plan
                                                    </label>
                                                    <div className='col-md-10'>
                                                        <AvField
                                                            name='name'
                                                            className='form-control'
                                                            type='text'
                                                            required
                                                            placeholder='Entrez le nom de votre business plan'
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: 'Champ invalide'
                                                                },
                                                                pattern: {
                                                                    value: '/^((?:[A-Za-z]+)(?:[A-Za-z0-9 ]*)|(?:[A-Za-z0-9 ]*)|)$/',
                                                                    errorMessage:
                                                                        "Seuls les chiffres, les lettres et l'espace sont autorisés"
                                                                },
                                                                minLength: {
                                                                    value: 4,
                                                                    errorMessage:
                                                                        'Merci de saisir au moins 4 caractères'
                                                                }
                                                            }}
                                                            errorMessage='Champ invalide'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='form-group row'>
                                                    <label
                                                        htmlFor='example-text-input'
                                                        className='col-md-2 col-form-label'
                                                    >
                                                        Secteur
                                                    </label>
                                                    <div className='col-md-10'>
                                                        <AvField
                                                            type='select'
                                                            name='type'
                                                            errorMessage='Champ obligatoire'
                                                            required
                                                        >
                                                            <option value='' hidden>
                                                                Choisissez le secteur de votre société
                                                            </option>
                                                            {bp_type.map((obj, index) => {
                                                                if (obj._name !== 'Autre') {
                                                                    return (
                                                                        <option
                                                                            name='bp_type'
                                                                            key={index}
                                                                            value={obj._id}
                                                                        >
                                                                            {obj._name}
                                                                        </option>
                                                                    );
                                                                }
                                                            })}
                                                            <option name='bp_type' value='1'>
                                                                Autre
                                                            </option>
                                                        </AvField>
                                                    </div>
                                                </div>
                                                <label
                                                    htmlFor='example-text-input'
                                                    className="col-md-2 col-form-label">
                                                    Votre projet est-il déjà créé ?
                                                </label>
                                                <div className='form-check form-check-right mb-3'>
                                                    <AvInput type='checkbox' name='project_created' />
                                                    <label
                                                        className='form-check-label'
                                                        onClick={() => {
                                                            this.setState({
                                                                customchk: !this.state.customchk
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <br />
                                                <b>
                                                    Certains contrôles sont spécifiques aux sociétés innovantes ( par ex
                                                    CIR, CII, JEI, production immobilisée…). <br />
                                                    Si c’est le cas pour votre projet ou entreprise, merci de cocher la
                                                    case suivante
                                                </b>
                                                <div className='form-check form-check-right mb-3'>
                                                    <AvInput type='checkbox' name='innovant' />
                                                    <label
                                                        className='form-check-label'
                                                        onClick={() => {
                                                            this.setState({
                                                                customchk: !this.state.customchk
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </CardBody>
                                        </Card>

                                        <Button color='primary' type='submit'>
                                            Initialiser le business plan
                                        </Button>
                                    </AvForm>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }
    }
}

export default withNamespaces()(keyBusinessPlan);

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Table,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../components/ThemeCMBP/Partials/Breadcrumb';

//i18n
import { withNamespaces } from 'react-i18next';

class indexModule extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    togglemodal = () => {
        this.setState(prevState => ({}));
    };

    render() {
        return (
            <React.Fragment>
                <div className='page-content'>
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs title={this.props.t('dashboard')} breadcrumbItem={this.props.t('moduleList')} />

                        <Row>
                            <Col lg='12'>
                                <div className=''>
                                    <div className='table-responsive'>
                                        <Table className='project-list-table table-nowrap table-centered table-borderless'>
                                            <thead>
                                                <tr>
                                                    <th scope='col' style={{ width: '100px' }}>
                                                        #
                                                    </th>
                                                    <th scope='col'>Name of module</th>
                                                    <th scope='col'>Category</th>
                                                    <th scope='col'>Status</th>
                                                    <th scope='col'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <i className='bx bx-briefcase mr-2'></i>
                                                    </td>
                                                    <td>
                                                        <h5 className='text-truncate font-size-14'>
                                                            <Link to='#' className='text-dark'>
                                                                Module P&L
                                                            </Link>
                                                        </h5>
                                                        <p className='text-muted mb-0'>getDescription</p>
                                                    </td>
                                                    <td>getCategory</td>
                                                    <td>
                                                        <span className='badge badge-primary'>Available</span>
                                                    </td>
                                                    <td>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle href='#' className='card-drop' tag='i'>
                                                                <i className='mdi mdi-dots-horizontal font-size-18'></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu right>
                                                                <DropdownItem
                                                                    tag={Link}
                                                                    to={{
                                                                        pathname: '/modules/view',
                                                                        state: { bp: 'p&l' }
                                                                    }}
                                                                >
                                                                    Start
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <i className='bx bx-briefcase mr-2'></i>
                                                    </td>
                                                    <td>
                                                        <h5 className='text-truncate font-size-14'>
                                                            <Link to='#' className='text-dark'>
                                                                Module Test
                                                            </Link>
                                                        </h5>
                                                        <p className='text-muted mb-0'>getDescription</p>
                                                    </td>
                                                    <td>getCategory</td>
                                                    <td>
                                                        <span className='badge badge-primary'>Available</span>
                                                    </td>
                                                    <td>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle href='#' className='card-drop' tag='i'>
                                                                <i className='mdi mdi-dots-horizontal font-size-18'></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu right>
                                                                <DropdownItem
                                                                    tag={Link}
                                                                    to={{
                                                                        pathname: '/modules/view',
                                                                        state: { bp: 'test' }
                                                                    }}
                                                                >
                                                                    Start
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <i className='bx bx-briefcase mr-2'></i>
                                                    </td>
                                                    <td>
                                                        <h5 className='text-truncate font-size-14'>
                                                            <Link to='#' className='text-dark'>
                                                                Module Beta
                                                            </Link>
                                                        </h5>
                                                        <p className='text-muted mb-0'>getDescription</p>
                                                    </td>
                                                    <td>getCategory</td>
                                                    <td>
                                                        <span className='badge badge-primary'>Available</span>
                                                    </td>
                                                    <td>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle href='#' className='card-drop' tag='i'>
                                                                <i className='mdi mdi-dots-horizontal font-size-18'></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu right>
                                                                <DropdownItem
                                                                    tag={Link}
                                                                    to={{
                                                                        pathname: '/modules/view',
                                                                        state: { bp: 'beta' }
                                                                    }}
                                                                >
                                                                    Start
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(indexModule);

import React, { Component } from 'react';

//i18n
import { withNamespaces } from 'react-i18next';

class rowListBP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            module: null,
            uuid: null,
            class: null,
            warning: 0,
            valid: 0,
            invalid: 0,
            description: null,
            moduleStatus: null,
            isLoading: true
        };
    }

    componentDidMount() {
        this.setState({
            name: this.props.name,
            module: this.props.module,
            uuid: this.props.uuid,
            description: this.props.description
        });
        this.setModuleStatus(this.props.module._moduleStatus._name);
        this.setDescription(this.props.module._moduleType._id);
        this.checkData(this.props.module);
    }

    checkData() {
        // eslint-disable-next-line
        let warning = 0;
        let valid = 0;
        let invalid = 0;
        Object.entries(this.props.module._infoAnnuelles).map(el => {
            let elementSchema = el[1];
            let valueToAdd = 0;
            for (let i = 0; i < 5; i++) {
                if (elementSchema[i]._statut === 3 && valueToAdd < 1) {
                    valueToAdd = 1;
                }
                if (elementSchema[i]._statut === 1 && valueToAdd < 1.5) {
                    valueToAdd = 1.5;
                }
                if (elementSchema[i]._statut === 2 && valueToAdd < 2) {
                    valueToAdd = 2;
                }
                if (elementSchema[i]._statut === 4 && valueToAdd < 3) {
                    valueToAdd = 3;
                }
            }
            switch (valueToAdd) {
                case 1:
                    valid++;
                    break;
                case 2:
                    warning++;
                    break;
                case 3:
                    invalid++;
                    break;
                default:
            }
            return true;
        });
        this.setState({ valid: valid });
        this.setState({ warning: warning });
        this.setState({ invalid: invalid });
    }

    setModuleStatus(status) {
        let list = {
            'missing data': 'info',
            warning: 'warning',
            valid: 'success',
            invalid: 'danger'
        };

        this.setState({ moduleStatus: list[status] });
    }

    setDescription(id) {
        let list = {
            1: '_modulePL_desc',
            2: '_moduleBilan_desc',
            3: '_moduleChargePersonnel_desc',
            4: '_moduleBFR_desc',
            5: '_modulePlanDeTresorerie_desc',
            6: '_moduleLeveeDeFonds_desc',
            7: '_moduleFinancement_desc'
        };

        this.setState({ description: list[id], isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <p>Loading ...</p>;
        } else {
            const { module, moduleStatus, name, invalid, valid, warning } = this.state;

            return (
                <React.Fragment>
                    <tr>
                        <td>
                            <h5 className='text-truncate font-size-14'>{this.props.t(name)}</h5>
                        </td>
                        <td style={{ width: '100px' }}>
                            <span className={`badge badge-soft-${moduleStatus}-lg`} style={{ width: '100px' }}>
                                {this.props.t(module._moduleStatus._name)}
                            </span>
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                            <b>{valid}</b>
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                            <b>{warning}</b>
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                            <b>{invalid}</b>
                        </td>
                    </tr>
                </React.Fragment>
            );
        }
    }
}

export default withNamespaces()(rowListBP);

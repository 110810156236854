import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { axiosInstance } from '../../helpers/axios';

import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Progress, Button } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../components/ThemeCMBP/Partials/Breadcrumb';

//i18n
import { withNamespaces } from 'react-i18next';

class indexModule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            template: null,
            isLoading: true,
            errorMessage: null
        };
    }

    componentDidMount() {
        // const { bp } = this.props.location.state || 'none';
    }

    render() {
        const data = this.props.location.state;
        return (
            <React.Fragment>
                <div className='page-content'>
                    <Container fluid>
                        <Breadcrumbs
                            title={this.props.t('dashboard')}
                            breadcrumbItem={this.props.t(`mon module ${data.bp}`)}
                        />

                        <Row>
                            <Col xl='4' lg='4' md='12' sm='12'>
                                <Card>
                                    <CardBody>
                                        <CardTitle className='mb-4'>Récapitulatif de votre Business Plan</CardTitle>

                                        <div className='table-responsive mt-4'>
                                            <table className='table table-centered table-nowrap mb-2'>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '30%' }}>
                                                            <p className='mb-0'>Correct</p>
                                                        </td>
                                                        <td style={{ width: '25%' }}>
                                                            <h5 className='mb-0'>5</h5>
                                                        </td>
                                                        <td>
                                                            <Progress
                                                                value='50'
                                                                color='success'
                                                                className='bg-transparent'
                                                                size='sm'
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className='mb-0'>Warning</p>
                                                        </td>
                                                        <td>
                                                            <h5 className='mb-0'>2</h5>
                                                        </td>
                                                        <td>
                                                            <Progress
                                                                value='20'
                                                                color='warning'
                                                                className='bg-transparent'
                                                                size='sm'
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p className='mb-0'>Danger</p>
                                                        </td>
                                                        <td>
                                                            <h5 className='mb-0'>3</h5>
                                                        </td>
                                                        <td>
                                                            <Progress
                                                                value='30'
                                                                color='danger'
                                                                className='bg-transparent'
                                                                size='sm'
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl='8' lg='8' md='12' sm='12'>
                                <Card>
                                    <CardBody>
                                        <h4 className='card-title'>En détails vos problèmes</h4>

                                        <Row>
                                            <Col lg='4'>
                                                <div className='border p-3 rounded mt-4'>
                                                    <div className='d-flex align-items-center mb-3'>
                                                        <div className='avatar-xs mr-3'>
                                                            <span className='avatar-title rounded-circle bg-soft-warning text-warning font-size-18'>
                                                                <i className='mdi mdi-currency-usd'></i>
                                                            </span>
                                                        </div>
                                                        <h5 className='font-size-14 mb-0'>CA trop faible</h5>
                                                    </div>

                                                    <Row>
                                                        <div className='col-lg-12'>
                                                            <div className='text-muted mt-3'>
                                                                <p>
                                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                                    elit, sed do eiusmod tempor incididunt ut labore et
                                                                    dolore magna aliqua. Ut enim ad minim veniam, quis
                                                                    nostrud exercitation ullamco
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-12'>
                                                            <div className='float-right mt-3'>
                                                                <Link to='#' className='btn btn-primary'>
                                                                    Corriger la valeur
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col lg='4'>
                                                <div className='border p-3 rounded mt-4'>
                                                    <div className='d-flex align-items-center mb-3'>
                                                        <div className='avatar-xs mr-3'>
                                                            <span className='avatar-title rounded-circle bg-soft-primary text-primary font-size-18'>
                                                                <i className='mdi mdi-currency-usd'></i>
                                                            </span>
                                                        </div>
                                                        <h5 className='font-size-14 mb-0'>Rentabilité trop faible</h5>
                                                    </div>

                                                    <Row>
                                                        <div className='col-lg-12'>
                                                            <div className='text-muted mt-3'>
                                                                <p>
                                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                                    elit, sed do eiusmod tempor incididunt ut labore et
                                                                    dolore magna aliqua. Ut enim ad minim veniam, quis
                                                                    nostrud exercitation ullamco
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-12'>
                                                            <div className='float-right mt-3'>
                                                                <Link to='#' className='btn btn-primary'>
                                                                    Corriger la valeur
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col lg='4'>
                                                <div className='border p-3 rounded mt-4'>
                                                    <div className='d-flex align-items-center mb-3'>
                                                        <div className='avatar-xs mr-3'>
                                                            <span className='avatar-title rounded-circle bg-soft-info text-info font-size-18'>
                                                                <i className='mdi mdi-currency-usd'></i>
                                                            </span>
                                                        </div>
                                                        <h5 className='font-size-14 mb-0'>Incohérence</h5>
                                                    </div>

                                                    <Row>
                                                        <div className='col-lg-12'>
                                                            <div className='text-muted mt-3'>
                                                                <p>
                                                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                                                    elit, sed do eiusmod tempor incididunt ut labore et
                                                                    dolore magna aliqua. Ut enim ad minim veniam, quis
                                                                    nostrud exercitation ullamco
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-12'>
                                                            <div className='float-right mt-3'>
                                                                <Link to='#' className='btn btn-primary'>
                                                                    Corriger la valeur
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg='12'>
                                <Card>
                                    <CardBody>
                                        <CardTitle>Mes informations</CardTitle>
                                        <CardSubtitle className='mb-3'>
                                            Renseignez les valeurs dans les champs appropriés et obtenez le résultat de
                                            votre business plan en le validant en bas de page.
                                        </CardSubtitle>

                                        <div className='form-group row'>
                                            <label htmlFor='example-text-input' className='col-md-2 col-form-label'>
                                                Text
                                            </label>
                                            <div className='col-md-10'>
                                                <input
                                                    className='form-control'
                                                    type='text'
                                                    defaultValue='Artisanal kale'
                                                />
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label htmlFor='example-tel-input' className='col-md-2 col-form-label'>
                                                Telephone
                                            </label>
                                            <div className='col-md-10'>
                                                <input
                                                    className='form-control'
                                                    type='tel'
                                                    defaultValue='1-(555)-555-5555'
                                                />
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label htmlFor='example-number-input' className='col-md-2 col-form-label'>
                                                Number
                                            </label>
                                            <div className='col-md-10'>
                                                <input
                                                    className='form-control'
                                                    type='number'
                                                    defaultValue='42'
                                                    id='example-number-input'
                                                />
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label htmlFor='example-date-input' className='col-md-2 col-form-label'>
                                                Date
                                            </label>
                                            <div className='col-md-10'>
                                                <input
                                                    className='form-control'
                                                    type='date'
                                                    defaultValue='2019-08-19'
                                                    id='example-date-input'
                                                />
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label className='col-md-2 col-form-label'>Select</label>
                                            <div className='col-md-10'>
                                                <select className='form-control'>
                                                    <option>Select</option>
                                                    <option>Large select</option>
                                                    <option>Small select</option>
                                                </select>
                                            </div>
                                        </div>
                                        <Button color='primary' type='submit'>
                                            Valider
                                        </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(indexModule);

import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

//i18n
import { withNamespaces } from 'react-i18next';

// users
import user1 from '../../../assets/images/users/avatar-0.png';

class ProfileMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            name: 'Admin',
            isSuperAdmin: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    componentDidMount() {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            this.setState({ name: obj.username });
            if (obj.user_info != undefined) {
                var droits = obj.user_info.droits_user;
                if (droits.length != 0) {
                    this.setState({ isSuperAdmin: droits.find(x => x._id_droit == 1) != undefined });
                }
            }
        }
    }

    render() {
        const isSuperAdmin = this.state.isSuperAdmin;
        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className='d-inline-block'>
                    <DropdownToggle
                        className='btn header-item waves-effect'
                        id='page-header-user-dropdown'
                        tag='button'
                    >
                        <img className='rounded-circle header-profile-user' src={user1} alt='Header Avatar' />
                        <span className='d-none d-xl-inline-block ml-2 mr-1'>{this.state.name}</span>
                        <i className='mdi mdi-chevron-down d-none d-xl-inline-block'></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem tag={Link} to='/profile'>
                            <i className='bx bx-user font-size-16 align-middle mr-1'></i>
                            {this.props.t('profile')}
                        </DropdownItem>
                        <DropdownItem tag={Link} to='/factures'>
                            <i className='bx bx-file font-size-16 align-middle mr-1'></i>
                            {this.props.t('Mes factures')}
                        </DropdownItem>
                        {isSuperAdmin && (
                            <DropdownItem tag={Link} to='/init-facture-virement'>
                                <i className='bx bx-file font-size-16 align-middle mr-1'></i>
                                {this.props.t('Edition de facture')}
                            </DropdownItem>
                        )}
                        {isSuperAdmin && (
                            <DropdownItem tag={Link} to='/confirm-facture-virement'>
                                <i className='bx bx-file font-size-16 align-middle mr-1'></i>
                                {this.props.t('Confirmation des factures')}
                            </DropdownItem>
                        )}
                        <div className='dropdown-divider'></div>
                        <Link to='/logout' className='dropdown-item'>
                            <i className='bx bx-power-off font-size-16 align-middle mr-1 text-danger'></i>
                            <span>{this.props.t('logout')}</span>
                        </Link>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

export default withRouter(withNamespaces()(ProfileMenu));

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { axiosInstance } from '../../helpers/axios';

import { Container, Row, Col, Card, CardBody, Alert, Button } from 'reactstrap';
import { Redirect } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/ThemeCMBP/Partials/Breadcrumb';

import ModuleListBP from '../../components/ThemeCMBP/BusinessPlan/ModuleListBP';
import BusinessPlanDetail from '../../components/ThemeCMBP/BusinessPlan/BusinessPlanDetail';

//i18n
import { withNamespaces } from 'react-i18next';

class viewBusinessPlan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bp: null,
            bp_name: null,
            bp_type: null,
            module_list: null,
            user: null,
            isLoading: true,
            errorMessage: null,
            redirect: false,
            isMessage: false,
            messageClass: null,
            message: null,
            archived: false
        };
    }

    componentDidMount() {
        const {
            match: { params }
        } = this.props;
        if (this.props.location?.state?.isMessage) {
            this.setState({
                isMessage: this.props.location.state.isMessage,
                message: this.props.location.state.message,
                messageClass: this.props.location.state.messageClass
            });
        }
        axiosInstance
            .get(process.env.REACT_APP_API_URL + '/user/me')
            .then(res => {
                this.setState({ user: res.data });
            })
            .then(res => {
                axiosInstance
                    .get(process.env.REACT_APP_API_URL + '/business-plan/parsed/uuid/' + params.id)
                    .then(res => {
                        if (res.data._user._id !== this.state.user._id) {
                            this.setState({ redirect: true });
                        } else {
                            this.setState({
                                bp: res.data,
                                bp_name: res.data._name,
                                module_list: res.data._modules,
                                isLoading: false
                            });
                        }
                    })
                    .catch(err => {
                        this.setState({ errorMessage: err.message });
                    });
            })
            .catch(err => {
                this.setState({ errorMessage: err.message });
            });
    }

    render() {
        const { redirect, isMessage, message, messageClass, archived } = this.state;

        const goToBilan = () => {
            this.props.history.push('/business-plan/bilan/' + this.state.bp._uuid_bp);
        };

        const archiveBusinessPlan = () => {
            axiosInstance
                .put(process.env.REACT_APP_API_URL + '/business-plan/archive/' + this.state.bp._id)
                .then(res => {
                    console.log("Bp correctement archivé");
                    this.setState({ archived: true });
                })
                .catch(err => {
                    console.error("Erreur lors de l'archivage du bp");
                    this.setState({ errorMessage: err.message });
                });
        }

        if (archived) {
            return (
                <Redirect
                    to={{
                        pathname: '/business-plan/list',
                        message: "Bp archivé"
                    }}
                />
            );
        }
        if (redirect) {
            return (
                <Redirect
                    to={{
                        pathname: '/business-plan/list',
                        message: "vous n'avez pas les droits suffisants"
                    }}
                />
            );
        }
        if (this.state.isLoading) {
            return <p>Loading ...</p>;
        } else {
            const data = this.state;
            const module_list = this.state.module_list;
            return (
                <React.Fragment>
                    <div className='page-content'>
                        <Container fluid>
                            <Breadcrumbs title={this.props.t('Liste des BP')} breadcrumbItem='Votre Business Plan' />
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg='9' md='9' sm='9' xs='9'>
                                            <BusinessPlanDetail obj={data} />
                                            {isMessage && (
                                                <Alert color={messageClass} role='alert'>
                                                    {message}
                                                </Alert>
                                            )}
                                        </Col>
                                        <Col lg='3'>
                                            <Button
                                                onClick={goToBilan}
                                                color='info'
                                                size='md'
                                                block
                                                style={{ marginTop: '50px' }}
                                            >
                                                Récapitulatif du business plan
                                            </Button>
                                            <Button
                                                onClick={archiveBusinessPlan}
                                                type='submit'
                                                color='danger'
                                                size='md'
                                                block
                                            >
                                                Archiver le Business Plan
                                            </Button>
                                        </Col>
                                        {

                                        /* <Col lg='3' md='4' sm='4' xs='4'>
                                        <Button type='submit' color='info' size='md' block className='mt-1'>
                                            Exporter le Business Plan
                                        </Button>
                                        <Button type='submit' color='warning' size='md' block>
                                            Partager le Business Plan
                                        </Button>

                                    </Col> */}
                                    </Row>
                                    <Row>
                                        <Col lg='12'>
                                            <Card>
                                                <CardBody>
                                                    <h4 className='card-title'>LES MODULES</h4>
                                                    <Row>
                                                        {module_list.map(obj => {
                                                            console.log(this.state.bp[obj]);
                                                            return (
                                                                <Col key={obj._id} lg='4'>
                                                                    <ModuleListBP
                                                                        name={obj}
                                                                        module={this.state.bp[obj]}
                                                                        uuid={this.state.bp._uuid_bp}
                                                                        description={this.state.bp}
                                                                    />
                                                                </Col>
                                                            );
                                                        })}
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Container>
                    </div>
                </React.Fragment >
            );
        }
    }
}
viewBusinessPlan.propTypes = {
    match: PropTypes.object
};

export default withNamespaces()(viewBusinessPlan);

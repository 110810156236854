import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { FORGET_PASSWORD, RENEW_PASSWORD } from './actionTypes';
import { userForgetPasswordSuccess, userForgetPasswordError, userRenewPasswordError, userRenewPasswordSuccess } from './actions';

//Include Both Helper File with needed methods
import { forgotPassword, renewPassword } from '../../../helpers/auth/authController';

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
    try {
        const response = yield call(forgotPassword, '/user/' + user.email + '/change');
        if (response === true) {
            yield put(
                userForgetPasswordSuccess('Le mail a été envoyé avec succès')
            );
            history.push('/login');
        } else {
            yield put(userForgetPasswordError("Un problème est survenu lors de la procédure d'initiation de renouvellement de mot de passe."));
        }
    } catch (error) {
        yield put(userForgetPasswordError("L'utilisateur n'existe pas"));
    }
}


function* renewPasswordConfirm({ payload: { values, history } }) {
    try {
        console.log(values);
        if (values.password == values.confirm) {
            const response = yield call(renewPassword, '/user/' + values.email + '/password', { token: values.token, password: values.password })
            if (response) {
                yield put(userRenewPasswordSuccess("Le mot de passe à été changé avec succès"));
                history.push('/login');
            }
        }
        else {
            yield put(userRenewPasswordError("Les deux mot de passe ne sont pas identiques."));
        }
    } catch (error) {
        yield put(userRenewPasswordError("Le changement de mot de passe n'as pas abouti"));
    }
}
export function* watchUserPasswordForget() {
    yield takeEvery(FORGET_PASSWORD, forgetUser);
}

export function* watchRenewPassword() {
    yield takeEvery(RENEW_PASSWORD, renewPasswordConfirm);
}

function* forgetPasswordSaga() {
    yield all([fork(watchUserPasswordForget), fork(watchRenewPassword)]);
}

export default forgetPasswordSaga;

import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import classname from 'classnames';

//i18n
import { withNamespaces } from 'react-i18next';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        var matchingMenuItem = null;
        var ul = document.getElementById('navigation');
        var items = ul.getElementsByTagName('a');
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = item => {
        item.classList.add('active');
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add('active'); // li
            const parent2 = parent.parentElement;
            parent2.classList.add('active'); // li
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add('active'); // li
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add('active'); // li
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add('active'); // li
                        const parent6 = parent5.parentElement;
                        if (parent6) {
                            parent6.classList.add('active'); // li
                        }
                    }
                }
            }
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div className='topnav'>
                    <div className='container-fluid'>
                        <nav className='navbar navbar-light navbar-expand-lg topnav-menu' id='navigation'>
                            <Collapse isOpen={this.props.menuOpen} className='navbar-collapse' id='topnav-menu-content'>
                                <ul className='navbar-nav'>
                                    <li className='nav-item'>
                                        <Link to='/dashboard' className='nav-link'>
                                            <i className='fas fa-desktop mr-2'></i>
                                            {this.props.t('dashboard')}
                                        </Link>
                                    </li>
                                    <li className='nav-item dropdown'>
                                        <Link
                                            className='nav-link dropdown-toggle arrow-none'
                                            onClick={e => {
                                                e.preventDefault();
                                                this.setState({ businessplanState: !this.state.businessplanState });
                                            }}
                                            to='/business-plan/list'
                                        >
                                            <i className='bx bx-home-circle mr-2' />
                                            {this.props.t('Business plans')} {this.props.menuOpen}
                                            <div className='arrow-down' />
                                        </Link>
                                        <div
                                            className={classname('dropdown-menu', {
                                                show: this.state.businessplanState
                                            })}
                                        >
                                            <Link to='/business-plan/list' className='dropdown-item'>
                                                {this.props.t('Liste des business plans')}
                                            </Link>
                                            <Link to='/business-plan/new' className='dropdown-item'>
                                                {this.props.t('Nouveau business plan')}
                                            </Link>
                                        </div>
                                    </li>
                                    <li className='nav-item'>
                                        <Link to='/faq' className='nav-link'>
                                            <i className='fas fa-question-circle mr-2'></i>
                                            {this.props.t('FAQ')}
                                        </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link to='/cgu' className='nav-link'>
                                            <i className='fas fa-book mr-2'></i>
                                            {this.props.t('CGU')}
                                        </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link to='/vulgarisation' className='nav-link'>
                                            <i className='fas fa-book mr-2'></i>
                                            {this.props.t('Vulgarisations')}
                                        </Link>
                                    </li>
                                </ul>
                            </Collapse>
                        </nav>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(withNamespaces()(Navbar));

import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Media,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from 'reactstrap';
import MetaTags from 'react-meta-tags';
import classnames from 'classnames';

//Import Breadcrumb
import Breadcrumbs from '../../components/ThemeCMBP/Partials/Breadcrumb';

class PagesFaqs extends Component {
    constructor() {
        super();
        this.state = {
            activeTab: '1'
        };
        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>FAQ | CheckMyBP</title>
                </MetaTags>
                <div className='page-content'>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title='Aide' breadcrumbItem='FAQ' />

                        <div className='checkout-tabs'>
                            <Row>
                                <Col lg='2'>
                                    <Nav className='flex-column' pills>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: this.state.activeTab === '1'
                                                })}
                                                onClick={() => {
                                                    this.toggleTab('1');
                                                }}
                                            >
                                                <i className='bx bx-question-mark d-block check-nav-icon mt-4 mb-2' />
                                                <p className='font-weight-bold mb-4'>Questions générales</p>
                                            </NavLink>
                                        </NavItem>
                                        {/* <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: this.state.activeTab === '3'
                                                })}
                                                onClick={() => {
                                                    this.toggleTab('3');
                                                }}
                                            >
                                                <i className='bx bx-video d-block check-nav-icon mt-4 mb-2' />
                                                <p className='font-weight-bold mb-4'>Tutoriel</p>
                                            </NavLink>
                                        </NavItem> */}
                                    </Nav>
                                </Col>
                                <Col lg='10'>
                                    <Card>
                                        <CardBody>
                                            <TabContent activeTab={this.state.activeTab}>
                                                <TabPane tabId='1'>
                                                    <CardTitle className='mb-5'>Questions générales</CardTitle>
                                                    <Media className='faq-box'>
                                                        <div className='faq-icon mr-3'>
                                                            <i className='bx bx-help-circle font-size-20 text-success' />
                                                        </div>
                                                        <Media body>
                                                            <h5 className='font-size-15'>
                                                                Préconisez-vous un ordre pour valider les différents
                                                                modules ?
                                                            </h5>
                                                            <p className='text-muted'>
                                                                Il est préférable de valider progressivement d’abord le
                                                                compte de résultat, puis les charges de personnel. Vous
                                                                pourrez ensuite estimer les financements nécessaires. En
                                                                toute première approximation, vous devez « absorber » en
                                                                terme de financement, les pertes des deux premières
                                                                années (ou seulement la perte de la première année le
                                                                cas échéant), auxquelles vous devez rajouter le BFR des
                                                                2 premières années par précaution. Si vous avez des
                                                                difficultés à estimer votre BFR, vous pouvez prendre
                                                                l’hypothèse simplifiée que votre BFR annuel représente
                                                                entre 15% à 30% de votre chiffre d’affaires annuel, en
                                                                fonction de votre activité.
                                                                <br />
                                                                <br />
                                                                Ce financement global sera partagé entre levée de
                                                                fonds/apport des actionnaires et emprunt. Vous pourrez
                                                                ainsi renseigner les deux modules correspondants. Vous
                                                                finaliserez votre cohérence des prévisions par les
                                                                modules trésorerie (votre solde de trésorerie annuel
                                                                devant toujours être positif) puis le bilan.
                                                            </p>
                                                        </Media>
                                                    </Media>
                                                    <br />
                                                    <Media className='faq-box'>
                                                        <div className='faq-icon mr-3'>
                                                            <i className='bx bx-help-circle font-size-20 text-success' />
                                                        </div>
                                                        <Media body>
                                                            <h5 className='font-size-15'>
                                                                Peut-on modifier les éléments saisis ?
                                                            </h5>
                                                            <p className='text-muted'>
                                                                Bien sûr. La validation progressive nécessitera
                                                                probablement un ajustement au fur et à mesure, jusqu’à
                                                                obtenir un maximum de voyants verts.
                                                            </p>
                                                        </Media>
                                                    </Media>
                                                    <br />
                                                    <Media className='faq-box'>
                                                        <div className='faq-icon mr-3'>
                                                            <i className='bx bx-help-circle font-size-20 text-success' />
                                                        </div>
                                                        <Media body>
                                                            <h5 className='font-size-15'>
                                                                Tous les voyants doivent-ils être verts ?
                                                            </h5>
                                                            <p className='text-muted'>
                                                                C’est mieux, si possible, en fonction de vos objectifs.
                                                                A minima, vous serez alertés sur les points de
                                                                vigilance. Pour une recherche de financement, un maximum
                                                                de voyans verts est néanmoins préconisé.
                                                            </p>
                                                        </Media>
                                                    </Media>
                                                    <br />
                                                    <Media className='faq-box'>
                                                        <div className='faq-icon mr-3'>
                                                            <i className='bx bx-help-circle font-size-20 text-success' />
                                                        </div>
                                                        <Media body>
                                                            <h5 className='font-size-15'>
                                                                Quand la cohérence d’un module est-elle effectuée ?
                                                            </h5>
                                                            <p className='text-muted'>
                                                                Vous devez d’abord renseigner l’intégralité d’un module,
                                                                avant que la cohérence de chaque question soit calculée.
                                                                Les cases correspondantes sont alors soit verte, soit
                                                                rouge en cas d’incohérence.
                                                            </p>
                                                        </Media>
                                                    </Media>
                                                    <br />
                                                    <Media className='faq-box'>
                                                        <div className='faq-icon mr-3'>
                                                            <i className='bx bx-help-circle font-size-20 text-success' />
                                                        </div>
                                                        <Media body>
                                                            <h5 className='font-size-15'>
                                                                Quelles sont les suggestions proposéees lors d’une
                                                                incohérence ?
                                                            </h5>
                                                            <p className='text-muted'>
                                                                En cas d’incohérence, des pistes d’améliorations sont
                                                                suggérées afin de vous permettre d’effectuer des
                                                                changements, à votre rythme.
                                                            </p>
                                                        </Media>
                                                    </Media>
                                                    <br />
                                                    <Media className='faq-box'>
                                                        <div className='faq-icon mr-3'>
                                                            <i className='bx bx-help-circle font-size-20 text-success' />
                                                        </div>
                                                        <Media body>
                                                            <h5 className='font-size-15'>
                                                                En quoi consiste l’aide contextuelle ?
                                                            </h5>
                                                            <p className='text-muted'>
                                                                CheckMyBP vous permet d’appréhender les principales
                                                                notions financières et le cas échéant de préciser les
                                                                enjeux associés. Elle ne peut être exhaustive, mais nous
                                                                avons essayé de vous faire part des principaux points de
                                                                connaissance ou vigilance.
                                                            </p>
                                                        </Media>
                                                    </Media>
                                                    <br />
                                                    <Media className='faq-box'>
                                                        <div className='faq-icon mr-3'>
                                                            <i className='bx bx-help-circle font-size-20 text-success' />
                                                        </div>
                                                        <Media body>
                                                            <h5 className='font-size-15'>
                                                                Est-on obligé de remplir tout un module avant d’en
                                                                renseigner un second ?
                                                            </h5>
                                                            <p className='text-muted'>
                                                                Pas obligatoirement, vous pouvez effectuer d’abord un
                                                                premier survol des différents modules, et renseigner les
                                                                champs déjà connus. Vous complèterez alors les champs au
                                                                fur et à mesure.
                                                            </p>
                                                        </Media>
                                                    </Media>
                                                    <br />
                                                    <Media className='faq-box'>
                                                        <div className='faq-icon mr-3'>
                                                            <i className='bx bx-help-circle font-size-20 text-success' />
                                                        </div>
                                                        <Media body>
                                                            <h5 className='font-size-15'>
                                                                Pendant combien de temps, peut-on effectuer des
                                                                corrections ou ajouts ?
                                                            </h5>
                                                            <p className='text-muted'>
                                                                Vous pouvez effectuer toutes les modifications
                                                                souhaitées pendant une période de six mois. Au-delà vos
                                                                données seront supprimées.
                                                            </p>
                                                        </Media>
                                                    </Media>
                                                    <br />
                                                    <Media className='faq-box'>
                                                        <div className='faq-icon mr-3'>
                                                            <i className='bx bx-help-circle font-size-20 text-success' />
                                                        </div>
                                                        <Media body>
                                                            <h5 className='font-size-15'>
                                                                Faut-il renseigner tous les champs d’un module ?
                                                            </h5>
                                                            <p className='text-muted'>
                                                                La plupart des contrôles sont indépendants du secteur
                                                                d’activité, vous pouvez ainsi remplir l’ensemble des
                                                                champs. Quelques champs sont néanmoins spécifiques, et
                                                                peuvent ne pas s’appliquer à votre société. Typiquement,
                                                                les champs liés au statut JEI, production immobilisée,
                                                                CIR/CII ne concernent que les sociétés innovantes.
                                                                Concernant le module levée de fonds, il n’est pas à
                                                                renseigner si vous ne souhaitez pas en effectuer une.
                                                                Certains champs sont grisés et ne nécessitent pas de
                                                                renseigner de valeur. Dans certains cas, des champs
                                                                afficheront des valeurs calculées par le logiciel.
                                                            </p>
                                                        </Media>
                                                    </Media>
                                                    <br />
                                                    <Media className='faq-box'>
                                                        <div className='faq-icon mr-3'>
                                                            <i className='bx bx-help-circle font-size-20 text-success' />
                                                        </div>
                                                        <Media body>
                                                            <h5 className='font-size-15'>
                                                                Tous les secteurs d’activités sont-ils concernés par
                                                                CheckMyBP ?
                                                            </h5>
                                                            <p className='text-muted'>
                                                                Oui. Les notions abordées concernent tout type
                                                                d’activité. Il existe néanmoins, une exclusion
                                                                concernant les sociétés de biotechnologies sur les
                                                                aspects liés à la rentabilité et la valorisation
                                                                estimée. Ces spécifités ne sont pas prises en compte sur
                                                                ces deux points, l’ensemble des autres contrôles restant
                                                                valables. Les valorisations indicatives fournies dans le
                                                                module levée de fonds, concernent plus particulièrement
                                                                les sociétés de négoce/distribution, services, «
                                                                industrielles » ou de logiciels.
                                                            </p>
                                                        </Media>
                                                    </Media>
                                                    <br />
                                                    <Media className='faq-box'>
                                                        <div className='faq-icon mr-3'>
                                                            <i className='bx bx-help-circle font-size-20 text-success' />
                                                        </div>
                                                        <Media body>
                                                            <h5 className='font-size-15'>
                                                                Combien de BP peut-on valider ?
                                                            </h5>
                                                            <p className='text-muted'>
                                                                Lors de la connexion, vous avez reçu une clé
                                                                d’activation personnelle pour la validation d’un BP.
                                                                Vous pouvez le modifier autant de fois que nécessaire,
                                                                mais ne pourrez en remplir qu’un seul. La licence
                                                                d'utilisation est accordée à un seul projet ou société.
                                                                Si vous souhaitez utiliser le logiciel pour plusieurs
                                                                sociétés, contactez nous par mail à{' '}
                                                                <a href='mailto:info@checkmybp.fr'>info@checkmybp.fr</a>
                                                                &nbsp;pour trouver ensemble la solution la plus adaptée.
                                                            </p>
                                                        </Media>
                                                    </Media>
                                                    <br />
                                                    <Media className='faq-box'>
                                                        <div className='faq-icon mr-3'>
                                                            <i className='bx bx-help-circle font-size-20 text-success' />
                                                        </div>
                                                        <Media body>
                                                            <h5 className='font-size-15'>
                                                                Je souhaite soumettre une idée d’amélioration ?
                                                            </h5>
                                                            <p className='text-muted'>
                                                                CheckMyBP est à l’écoute de ses utilisateurs dans le
                                                                souci d’améliorer en permanence notre solution, afin de
                                                                répondre toujours mieux à vos attentes. Vous pouvez
                                                                ainsi nous faire part de vos préconisations par mail à
                                                                l’adresse{' '}
                                                                <a href='mailto:info@checkmybp.fr'>info@checkmybp.fr</a>
                                                                .
                                                            </p>
                                                        </Media>
                                                    </Media>
                                                </TabPane>
                                                {/* <TabPane tabId='3'>
                                                    <CardTitle className='mb-5'>Tutoriel</CardTitle>
                                                </TabPane> */}
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default PagesFaqs;

import React, { Component } from 'react';
import { Row, Col, Alert, Card, CardBody, Container } from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// action
import { userForgetPassword, userRenewPassowrd } from '../../store/actions';

// import images
import profile from '../../assets/images/profile-img.png';
import logo from '../../assets/images/checkmybp/logo-icone.png';

//i18n
import { withNamespaces } from 'react-i18next';

class ForgetPasswordPage extends Component {
    constructor(props) {
        super(props);
        var query = new URLSearchParams(this.props.location.search)
        this.state = {
            email: query != null ? query.get('email') : null,
            token: query.get('token')
        };
        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.setState({ email: values.email });
        this.props.userForgetPassword(values, this.props.history);
    }

    handleChangePassword(event, values) {
        values = { ...values, email: this.state.email, token: this.state.token };
        this.props.userRenewPassowrd(values, this.props.history);
    }

    render() {
        return (
            <React.Fragment>
                <div className='home-btn d-none d-sm-block'>
                    <Link to='/' className='text-dark'>
                        <i className='bx bx-home h2'></i>
                    </Link>
                </div>
                <div className='account-pages my-5 pt-sm-5'>
                    <Container>
                        <Row className='justify-content-center'>
                            <Col md={8} lg={6} xl={5}>
                                <Card className='overflow-hidden'>
                                    <div className='bg-soft-primary'>
                                        <Row>
                                            <Col className='col-7'>
                                                <div className='text-primary p-4'>
                                                    <h5 className='text-primary'>Mot de passe oublié ?</h5>
                                                    <p>Suivez-nos instructions</p>
                                                </div>
                                            </Col>
                                            <Col className='col-5 align-self-end'>
                                                <img src={profile} alt='' className='img-fluid' />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className='pt-0'>
                                        <div>
                                            <Link to='/'>
                                                <div className='avatar-md profile-user-wid mb-4'>
                                                    <span className='avatar-title rounded-circle bg-light'>
                                                        <img src={logo} alt='' className='rounded-circle' height='34' />
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className=''>
                                            {this.props.forgetError && this.props.forgetError ? (
                                                <Alert color='danger' style={{ marginTop: '13px' }}>
                                                    {this.props.forgetError}
                                                </Alert>
                                            ) : null}
                                            {this.props.forgetSuccessMsg ? (
                                                <Alert color='success' style={{ marginTop: '13px' }}>
                                                    {this.props.forgetSuccessMsg}
                                                </Alert>
                                            ) : null}
                                            {this.state.token != null ? (
                                                <AvForm
                                                    className='form-horizontal mt-4'
                                                    onValidSubmit={this.handleChangePassword}
                                                >
                                                    <div className='form-group'>
                                                        <AvField
                                                            name='password'
                                                            label='Mot de passe'
                                                            type='password'
                                                            required
                                                            placeholder='Entrez votre nouveau mot de passe'
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: 'Ce champ est obligatoire'
                                                                },
                                                                pattern: {
                                                                    value: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$',
                                                                    errorMessage:
                                                                        'Ce champ doit contenir au moins 1 majuscule, 1 minuscule et 1 chiffre'
                                                                },
                                                                minLength: {
                                                                    value: 8,
                                                                    errorMessage: 'Ce champ doit faire 8 caractère minimum'
                                                                }
                                                            }}
                                                            errorMessage='Champ invalide'
                                                        />
                                                    </div>
                                                    <div className='form-group'>
                                                        <AvField
                                                            name='confirm'
                                                            label='Confirmez votre mot de passe'
                                                            type='password'
                                                            required
                                                            placeholder='confirmez votre mot de passe'
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: 'Ce champ est obligatoire'
                                                                },
                                                                pattern: {
                                                                    value: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$',
                                                                    errorMessage:
                                                                        'Ce champ doit contenir au moins 1 majuscule, 1 minuscule et 1 chiffre'
                                                                },
                                                                minLength: {
                                                                    value: 8,
                                                                    errorMessage: 'Ce champ doit faire 8 caractère minimum'
                                                                }
                                                            }}
                                                            errorMessage='Champ invalide'
                                                        />
                                                    </div>
                                                    <Row className='form-group'>
                                                        <Col className='text-right'>
                                                            <button
                                                                className='btn btn-primary w-md waves-effect waves-light'
                                                                type='submit'
                                                            >
                                                                Confirmer
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                            ) : (
                                                <AvForm
                                                    className='form-horizontal mt-4'
                                                    onValidSubmit={this.handleValidSubmit}
                                                >
                                                    <div className='form-group'>
                                                        <AvField
                                                            name='email'
                                                            label='Email'
                                                            className='form-control'
                                                            placeholder='Entrez votre email'
                                                            type='text'
                                                            errorMessage='Champ invalide'
                                                            required
                                                        />
                                                    </div>
                                                    <Row className='form-group'>
                                                        <Col className='text-right'>
                                                            <button
                                                                className='btn btn-primary w-md waves-effect waves-light'
                                                                type='submit'
                                                            >
                                                                Confirmer
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </AvForm>)}

                                        </div>
                                    </CardBody>
                                </Card>
                                <div className='mt-5 text-center'>
                                    <p>
                                        <Link to='login' className='font-weight-medium text-primary'>
                                            Revenir à la page de connexion
                                        </Link>{' '}
                                    </p>
                                    <p>
                                        {new Date().getFullYear()} © CheckMyBP. {this.props.t('designAndDevelop')}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { forgetError, forgetSuccessMsg, tokenChangePassword } = state.ForgetPassword;
    return { forgetError, forgetSuccessMsg, tokenChangePassword };
};

export default connect(mapStatetoProps, { userForgetPassword, userRenewPassowrd })(withNamespaces()(ForgetPasswordPage));

import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RENEW_PASSWORD,
  RENEW_PASSWORD_SUCCESS,
  RENEW_PASSWORD_ERROR
} from "./actionTypes";

export const userForgetPassword = (user, history) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user, history }
  };
};

export const userRenewPassowrd = (values, history) => {
  return {
    type: RENEW_PASSWORD,
    payload: { values, history }
  }
}

export const userForgetPasswordSuccess = (message, token) => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
    token: token
  };
};

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message
  };
};

export const userRenewPasswordSuccess = (message, token) => {
  return {
    type: RENEW_PASSWORD_SUCCESS,
    payload: message
  };
};

export const userRenewPasswordError = message => {
  return {
    type: RENEW_PASSWORD_ERROR,
    payload: message
  };
};

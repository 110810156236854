import React from 'react';
import PropTypes from 'prop-types';
import { Col, Media, Row } from 'reactstrap';
// import img1 from '../../../assets/images/companies/img-1.png';
import I18next from 'i18next';
import moment from 'moment';

const BusinessPlanDetail = ({ obj }) => {
    const currentLanguage = I18next.language;

    return (
        <div style={{ marginLeft: '25px' }}>
            <Row>
                <Media>
                    <Media className='overflow-hidden' body>
                        <h5 className='text-truncate font-size-15 ml-3'>{obj.bp_name}</h5>
                    </Media>
                </Media>
            </Row>

            <Row className='task-dates'>
                <Col sm='3' xs='3'>
                    <div className='mt-4'>
                        <h5 className='font-size-14'>
                            <i className='bx bx-calendar mr-1 text-primary' /> Créé le
                        </h5>
                        <p className='text-muted mb-0'>
                            {new Intl.DateTimeFormat(currentLanguage, {
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit'
                            }).format(moment(obj.bp._key._date_activation).toDate())}
                        </p>
                    </div>
                </Col>
                <Col sm='3' xs='3'>
                    <div className='mt-4'>
                        <h5 className='font-size-14'>
                            <i className='bx bx-calendar-check mr-1 text-primary' /> Expire le
                        </h5>
                        <p className='text-muted mb-0'>
                            {new Intl.DateTimeFormat(currentLanguage, {
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit'
                            }).format(moment(obj.bp._key._date_expiration).toDate())}
                        </p>
                    </div>
                </Col>

                <Col sm='6' xs='6'></Col>
            </Row>
        </div>
    );
};

BusinessPlanDetail.propTypes = {
    project: PropTypes.object
};

export default BusinessPlanDetail;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap';

// availity-reactstrap-validation
import { AvForm, AvField, AvRadio, AvRadioGroup } from 'availity-reactstrap-validation';

// action
import { registerUser, apiError, registerUserFailed } from '../../store/actions';

// Redux
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import images
import profileImg from '../../assets/images/profile-img.png';
import logoImg from '../../assets/images/checkmybp/logo-icone.png';

//i18n
import { withNamespaces } from 'react-i18next';
import axios from 'axios';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typeUser: []
        };

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.props.registerUser(values);
    }

    componentDidMount() {
        axios
            .get(process.env.REACT_APP_API_URL + '/user/type/all')
            .then(res => {
                this.setState({ typeUser: res.data });
            })
            .catch(err => {
                console.log(err)
                console.log(process.env.REACT_APP_API_URL + '/user/type/all')
            });
        this.props.apiError('');
        this.props.registerUserFailed('');
    }

    render() {
        return (
            <React.Fragment>
                <div className='home-btn d-none d-sm-block'>
                    <Link to='/' className='text-dark'>
                        <i className='bx bx-home h2'></i>
                    </Link>
                </div>
                <div className='account-pages my-5 pt-sm-5'>
                    <Container>
                        <Row className='justify-content-center'>
                            <Col md={8} lg={6} xl={5}>
                                <Card className='overflow-hidden'>
                                    <div className='bg-soft-primary'>
                                        <Row>
                                            <Col className='col-7'>
                                                <div className='text-primary p-4'>
                                                    <h5 className='text-primary'>Inscription à CheckMyBP</h5>
                                                </div>
                                            </Col>
                                            <Col className='col-5 align-self-end'>
                                                <img src={profileImg} alt='' className='img-fluid' />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className='pt-0'>
                                        <div>
                                            <Link to='/'>
                                                <div className='avatar-md profile-user-wid mb-4'>
                                                    <span className='avatar-title rounded-circle bg-light'>
                                                        <img
                                                            src={logoImg}
                                                            alt=''
                                                            className='rounded-circle'
                                                            height='34'
                                                        />
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className='p-2'>
                                            <AvForm className='form-horizontal' onValidSubmit={this.handleValidSubmit}>
                                                <div className='mb-2 mt-2'>
                                                    <b>Informations client</b>
                                                </div>
                                                <div className='form-group'>
                                                    <AvField
                                                        name='login'
                                                        label='Identifiant'
                                                        type='text'
                                                        required
                                                        placeholder='Entrez un identifiant'
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: 'Ce champ est obligatoire'
                                                            },
                                                            minLength: {
                                                                value: 3,
                                                                errorMessage: 'Ce champ doit faire 3 caractère minimum'
                                                            },
                                                            maxLength: {
                                                                value: 30,
                                                                errorMessage: 'Ce champ doit faire 30 caractère maximum'
                                                            },
                                                            pattern: {
                                                                value: '^[a-zA-Z0-9_ -]{3,30}$',
                                                                errorMessage:
                                                                    'Ce champ ne peut contenir que des chiffres, des lettres ou tirets'
                                                            }
                                                        }}
                                                        errorMessage='Champ invalide'
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <AvField
                                                        name='email'
                                                        label='Email'
                                                        type='email'
                                                        required
                                                        placeholder='Entrez votre adresse email'
                                                        errorMessage='Champ invalide'
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <AvField
                                                        name='password'
                                                        label='Mot de passe'
                                                        type='password'
                                                        required
                                                        placeholder='Entrez votre mot de passe'
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: 'Ce champ est obligatoire'
                                                            },
                                                            pattern: {
                                                                value: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$',
                                                                errorMessage:
                                                                    'Ce champ doit contenir au moins 1 majuscule, 1 minuscule et 1 chiffre'
                                                            },
                                                            minLength: {
                                                                value: 8,
                                                                errorMessage: 'Ce champ doit faire 8 caractère minimum'
                                                            }
                                                        }}
                                                        errorMessage='Champ invalide'
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <AvField
                                                        name='login_type'
                                                        type='text'
                                                        value='classic'
                                                        required
                                                        hidden
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <AvField
                                                        name='id_groupe_user'
                                                        type='text'
                                                        value='0'
                                                        required
                                                        hidden
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <AvField name='id_entite' type='text' value='0' required hidden />
                                                </div>
                                                <div className='form-group'>
                                                    <AvField name='id_status' type='text' value='0' required hidden />
                                                </div>
                                                <div className='form-group'>
                                                    <AvField
                                                        label='Type utilisateur'
                                                        type='select'
                                                        name='type_user'
                                                        required
                                                    >
                                                        <option value='' hidden>
                                                            Quel est votre profil utilisateur
                                                        </option>
                                                        {this.state.typeUser.map((obj, index) => {
                                                            return (
                                                                <option
                                                                    name='type_user'
                                                                    key={index}
                                                                    value={obj.id}
                                                                >
                                                                    {obj.nom}
                                                                </option>
                                                            );
                                                        })}
                                                    </AvField>
                                                </div>
                                                <div>
                                                    <AvRadioGroup inline name='civilite' label='Civilité' required>
                                                        <AvRadio customInput label='Madame' value='madame' />
                                                        <AvRadio customInput label='Monsieur' value='monsieur' />
                                                    </AvRadioGroup>
                                                </div>
                                                <div className='form-group'>
                                                    <AvField
                                                        name='nom'
                                                        label='Nom'
                                                        type='text'
                                                        required
                                                        placeholder='Entrez votre nom'
                                                        errorMessage='Champ invalide'
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <AvField
                                                        name='prenom'
                                                        label='Prénom'
                                                        type='text'
                                                        required
                                                        placeholder='Entrez votre prénom'
                                                        errorMessage='Champ invalide'
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <AvField
                                                        name='adresse'
                                                        label='Adresse'
                                                        type='text'
                                                        required
                                                        placeholder='Entrez votre adresse de résidence'
                                                        errorMessage='Champ invalide'
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <AvField
                                                        name='code_postal'
                                                        label='Code Postal'
                                                        type='number'
                                                        required
                                                        minLength='5'
                                                        maxLength='5'
                                                        placeholder='Entrez votre code Postal'
                                                        errorMessage='Champ invalide'
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <AvField
                                                        name='ville'
                                                        label='Ville'
                                                        type='text'
                                                        required
                                                        placeholder='Entrez votre ville'
                                                        errorMessage='Champ invalide'
                                                    />
                                                </div>
                                                <div className='mt-4'>
                                                    <button
                                                        className='btn btn-primary btn-block waves-effect waves-light'
                                                        type='submit'
                                                    >
                                                        S'inscrire
                                                    </button>
                                                </div>
                                                {this.props.user && this.props.user ? (
                                                    <Alert color='success'>
                                                        Votre enregistrement a été validé. <br /> Vous pouvez désormais
                                                        vous <Link to='/login'>connecter</Link>.

                                                    </Alert>
                                                ) : null}
                                                {this.props.apiError && this.props.registrationError ? (
                                                    <Alert color='danger'>{this.props.registrationError}</Alert>
                                                ) : null}
                                                <div className='mt-4 text-center'>
                                                    <p className='mb-0'>
                                                        En vous inscrivant vous acceptez les{' '}
                                                        <Link to='#' className='text-primary'>
                                                            Termes et Conditions d'utilisation
                                                        </Link>
                                                    </p>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className='mt-5 text-center'>
                                    <p>
                                        Vous avez déjà un compte ?{' '}
                                        <Link to='/login' className='font-weight-medium text-primary'>
                                            {' '}
                                            Se connecter
                                        </Link>{' '}
                                    </p>
                                    <p>
                                        {new Date().getFullYear()} © CheckMyBP. {this.props.t('designAndDevelop')}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div >
            </React.Fragment >
        );
    }
}

Register.propTypes = {
    apiError: PropTypes.any,
    registerUser: PropTypes.func,
    registerUserFailed: PropTypes.any,
    registrationError: PropTypes.any,
    user: PropTypes.object
};

const mapStatetoProps = state => {
    const { user, registrationError, loading } = state.Account;
    return { user, registrationError, loading };
};

export default connect(mapStatetoProps, { registerUser, apiError, registerUserFailed })(withNamespaces()(Register));

import React, { Component } from 'react';
import { Alert, Card, CardBody, CardTitle, CardSubtitle, Button, Container, Row, Col } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { Redirect, Link } from 'react-router-dom';
import { axiosInstance } from '../../helpers/axios';

//Import Breadcrumb
import Breadcrumbs from '../../components/ThemeCMBP/Partials/Breadcrumb';
import { newBP } from '../../helpers/businessPlan/businessPlan';

//i18n
import { withNamespaces } from 'react-i18next';

class keyBusinessPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            keys: null,
            bp: null,
            hasKey: false,
            numberKey: 0,
            isLoading: true,
            errorMessage: null,
            redirect: false,
            redirectPayment: false,
            redirectBP: false,
            statutPaiement: null,
            infoFacturations: []
        };
        this.handleKeyExist = this.handleKeyExist.bind(this);
        this.handleKeyList = this.handleKeyList.bind(this);
        this.handlePayment = this.handlePayment.bind(this);
    }

    componentDidMount() {
        if (this.props.location.state) {
            if (this.props.location.state.data.erreur === '00000') {
                this.setState({
                    statutPaiement: 1
                });
            } else if (this.props.location.state.data.erreur === '00001') {
                this.setState({
                    statutPaiement: 2
                });
            } else {
                this.setState({
                    statutPaiement: 0
                });
            }
            window.history.replaceState(null, '');
        }
        if (JSON.parse(localStorage.getItem('initBP'))) {
            axiosInstance
                .get(process.env.REACT_APP_API_URL + '/user/profile')
                .then(res => {
                    let userID = res.data.userId;
                    axiosInstance
                        .get(process.env.REACT_APP_API_URL + '/key/all')
                        .then(res => {
                            let keys = res.data[0].list_key;
                            if (keys !== null) {
                                this.setState({ hasKey: false });
                                res.data.forEach(element => {
                                    element.list_key.forEach(values => {
                                        if (!values._date_activation) {
                                            this.setState({ hasKey: true });
                                        }
                                    });
                                });
                            }
                            axiosInstance
                                .get(process.env.REACT_APP_API_URL + '/billing/info/user/' + userID)
                                .then(res => {
                                    this.setState({ keys: keys, infoFacturations: res.data, isLoading: false });
                                });
                        })
                        .catch(err => {
                            this.setState({ errorMessage: err.message });
                        });
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            this.setState({ redirect: true });
        }
    }

    handleKeyExist(event, values) {
        if (values.keyexist) {
            newBP(values);
            setTimeout(() => {
                this.setState({ redirectBP: true });
            }, 1000);
        }
    }

    handleKeyList(event, values) {
        if (values.keylist) {
            newBP(values);
            setTimeout(() => {
                this.setState({ redirectBP: true });
            }, 1000);
        }
    }

    handlePayment(event, values) {
        if (values.key <= 0) {
            this.setState({ errorMessage: 'Vous devez au minimum commander une clé !' });
        } else {
            localStorage.setItem('keyPayment', `{"key":"${values.key}"}`);
            localStorage.setItem('id_facturation', `{"id_facturation":"${values.id_facturation}"}`);
            this.setState({ numberKey: values.key, redirectPayment: true });
        }
    }

    render() {
        const {
            hasKey,
            keys,
            numberKey,
            redirect,
            redirectBP,
            redirectPayment,
            statutPaiement,
            infoFacturations
        } = this.state;

        if (redirect) {
            return (
                <Redirect
                    to={{
                        pathname: '/business-plan/new',
                        message: 'error'
                    }}
                />
            );
        }

        if (redirectBP) {
            return (
                <Redirect
                    to={{
                        pathname: '/business-plan/list',
                        message: 'Votre business plan a bien été créé !'
                    }}
                />
            );
        }

        if (redirectPayment) {
            return (
                <Redirect
                    to={{
                        pathname: '/business-plan/paiement',
                        state: { key: numberKey }
                    }}
                />
            );
        }

        if (this.state.isLoading) {
            return <p>Loading ...</p>;
        } else {
            return (
                <React.Fragment>
                    <div className='page-content'>
                        <Container fluid>
                            <Breadcrumbs
                                title={this.props.t('Mon Business Plan')}
                                breadcrumbItem={this.props.t('02 - Choix de la clé')}
                            />
                            {statutPaiement === 1 && (
                                <div className='alert alert-success' role='alert'>
                                    Votre paiement a été autorisé. Nous vous avons crédité en clé !
                                </div>
                            )}
                            {statutPaiement === 2 && (
                                <div className='alert alert-warning' role='alert'>
                                    Votre achat a été annulé et vous n'avez pas été débité
                                </div>
                            )}
                            {statutPaiement === 0 && (
                                <div className='alert alert-danger' role='alert'>
                                    Il y a eu une erreur lors de votre paiement. Vous n'avez pas été crédité en clé.
                                </div>
                            )}
                            <Row>
                                <Col lg='6'>
                                    <Card>
                                        <CardBody>
                                            <CardTitle>Renseigner une clé</CardTitle>
                                            <CardSubtitle className='mb-3'>
                                                Vous avez reçu une clé via un de nos partenaires ? Renseignez-la
                                                ci-dessous dans le champ dédié.
                                            </CardSubtitle>
                                            <br />
                                            {this.props.error && this.props.error ? (
                                                <Alert color='danger'>{this.props.error}</Alert>
                                            ) : null}
                                            <AvForm className='form-horizontal' onValidSubmit={this.handleKeyExist}>
                                                <div className='form-group row'>
                                                    <div className='col-md-12'>
                                                        <AvField
                                                            name='keyexist'
                                                            label='Votre clé :'
                                                            type='text'
                                                            errorMessage="Votre clé est invalide ou n'est pas au bon format"
                                                            validate={{
                                                                required: { value: true },
                                                                pattern: {
                                                                    value:
                                                                        '^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$'
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <br />
                                                <Button color='primary' type='submit'>
                                                    Utiliser cette clé
                                                </Button>
                                            </AvForm>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg='6'>
                                    <Card>
                                        {hasKey && (
                                            <CardBody>
                                                <CardTitle>Activer une clé</CardTitle>
                                                <CardSubtitle className='mb-3'>
                                                    Sélectionnez la clé à activer pour initialiser votre business plan.
                                                </CardSubtitle>
                                                {this.props.error && this.props.error ? (
                                                    <Alert color='danger'>{this.props.error}</Alert>
                                                ) : null}
                                                <AvForm className='form-horizontal' onValidSubmit={this.handleKeyList}>
                                                    <div className='form-group row'>
                                                        <div className='col-md-12'>
                                                            <AvField
                                                                type='select'
                                                                name='keylist'
                                                                label='Choisissez une clé :'
                                                                errorMessage='Champ invalide'
                                                            >
                                                                <option value='' hidden>
                                                                    Sélectionnez la clé
                                                                </option>
                                                                {/* eslint-disable-next-line */}
                                                                {keys.map((obj, index) => {
                                                                    if (!obj._date_activation) {
                                                                        return (
                                                                            <option
                                                                                name='key'
                                                                                key={index}
                                                                                value={obj._valeur}
                                                                            >
                                                                                {obj._valeur}
                                                                            </option>
                                                                        );
                                                                    }
                                                                })}
                                                            </AvField>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <Button color='primary' type='submit'>
                                                        Activer cette clé
                                                    </Button>
                                                </AvForm>
                                            </CardBody>
                                        )}
                                        {!hasKey && infoFacturations.length > 0 ? (
                                            <CardBody>
                                                <CardTitle>Acheter des clés</CardTitle>
                                                <CardSubtitle className='mb-3'>
                                                    Pour pouvoir initialiser votre business plan et obtenir une
                                                    vérification de la faisabilité de celui-ci, il vous faut une clé.
                                                    Choisissez ci-dessous le nombre de clés que vous souhaitez acquérir.
                                                </CardSubtitle>
                                                <br />
                                                {this.props.error && this.props.error ? (
                                                    <Alert color='danger'>{this.props.error}</Alert>
                                                ) : null}
                                                <AvForm className='form-horizontal' onValidSubmit={this.handlePayment}>
                                                    <div className='form-group row'>
                                                        <div className='col-md-12'>
                                                            <AvField
                                                                name='key'
                                                                className='form-control'
                                                                type='number'
                                                                defaultValue='1'
                                                                required
                                                            />
                                                            <AvField
                                                                type='select'
                                                                name='id_facturation'
                                                                label='Choisissez vos informations de facturation :'
                                                                errorMessage='Champ invalide'
                                                                required
                                                            >
                                                                <option value='' hidden>
                                                                    Choisir ses informations de facturation
                                                                </option>
                                                                {/* eslint-disable-next-line */}
                                                                {infoFacturations.map((obj, index) => {
                                                                    return (
                                                                        <option
                                                                            name='infoFacturations'
                                                                            key={index}
                                                                            value={obj._id}
                                                                        >
                                                                            {obj._nom_societe} - {obj._nom}{' '}
                                                                            {obj._prenom} - {obj._num_tva_communautaire}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </AvField>
                                                        </div>
                                                    </div>
                                                    <Button color='primary' type='submit'>
                                                        Procéder au paiement
                                                    </Button>
                                                </AvForm>
                                            </CardBody>
                                        ) : infoFacturations.length <= 0 ? (
                                            <CardBody>
                                                <CardTitle>Acheter des clés</CardTitle>
                                                <CardSubtitle className='mb-3'>
                                                    Afin de pouvoir procéder à l'achat d'une ou plusieurs clés
                                                    d'activation, merci de bien vouloir renseigner vos informations de
                                                    facturation.
                                                </CardSubtitle>
                                                <br />
                                                <Link to='/profile' className='btn btn-primary'>
                                                    Renseigner vos informations de facturation
                                                </Link>
                                            </CardBody>
                                        ) : (
                                            ''
                                        )}
                                    </Card>
                                </Col>
                                {hasKey && infoFacturations.length > 0 && (
                                    <Col lg='6'>
                                        <Card>
                                            {hasKey && infoFacturations.length > 0 ? (
                                                <CardBody>
                                                    <CardTitle>Acheter des clés</CardTitle>
                                                    <CardSubtitle className='mb-3'>
                                                        Pour pouvoir initialiser votre business plan et obtenir une
                                                        vérification de la faisabilité de celui-ci, il vous faut une
                                                        clé. Choisissez ci-dessous le nombre de clés que vous souhaitez
                                                        acquérir.
                                                    </CardSubtitle>
                                                    <br />
                                                    {this.props.error && this.props.error ? (
                                                        <Alert color='danger'>{this.props.error}</Alert>
                                                    ) : null}
                                                    <AvForm
                                                        className='form-horizontal'
                                                        onValidSubmit={this.handlePayment}
                                                    >
                                                        <div className='form-group row'>
                                                            <div className='col-md-12'>
                                                                <AvField
                                                                    name='key'
                                                                    className='form-control'
                                                                    type='number'
                                                                    defaultValue='1'
                                                                    errorMessage='Champ invalide'
                                                                    required
                                                                />
                                                                <AvField
                                                                    type='select'
                                                                    name='id_facturation'
                                                                    label='Choisissez vos informations de facturation :'
                                                                    errorMessage='Champ invalide'
                                                                    required
                                                                >
                                                                    <option value='' hidden>
                                                                        Choisir ses informations de facturation
                                                                    </option>
                                                                    {/* eslint-disable-next-line */}
                                                                    {infoFacturations.map((obj, index) => {
                                                                        return (
                                                                            <option
                                                                                name='infoFacturations'
                                                                                key={index}
                                                                                value={obj._id}
                                                                            >
                                                                                {obj._nom_societe} - {obj._nom}{' '}
                                                                                {obj._prenom} -{' '}
                                                                                {obj._num_tva_communautaire}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </AvField>
                                                            </div>
                                                        </div>
                                                        <Button color='primary' type='submit'>
                                                            Procéder au paiement
                                                        </Button>
                                                    </AvForm>
                                                </CardBody>
                                            ) : infoFacturations.length <= 0 ? (
                                                <CardBody>
                                                    <CardTitle>Acheter des clés</CardTitle>
                                                    <CardSubtitle className='mb-3'>
                                                        Afin de pouvoir procéder à l'achat d'une ou plusieurs clés
                                                        d'activation, merci de bien vouloir renseigner vos informations
                                                        de facturation.
                                                    </CardSubtitle>
                                                    <br />
                                                    <Link to='/profile' className='btn btn-primary'>
                                                        Renseigner vos informations de facturation
                                                    </Link>
                                                </CardBody>
                                            ) : (
                                                ''
                                            )}
                                        </Card>
                                    </Col>
                                )}
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }
    }
}

export default withNamespaces()(keyBusinessPlan);


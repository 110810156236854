import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { axiosInstance } from '../../../helpers/axios';

import { Row } from 'reactstrap';

//i18n
import { withNamespaces } from 'react-i18next';

class rowListBP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            module: null,
            uuid: null,
            class: null,
            warning: 0,
            valid: 0,
            invalid: 0,
            description: null,
            moduleStatus: null,
            isLoading: true
        };
    }

    componentDidMount() {
        this.setState({
            name: this.props.name,
            module: this.props.module,
            uuid: this.props.uuid,
            description: this.props.description
        });
        this.setModuleStatus(this.props.module._moduleStatus._name);
        this.setDescription(this.props.module._moduleType._id);
        this.checkData(this.props.module);
    }

    checkData() {
        // eslint-disable-next-line
        let warning = 0;
        let valid = 0;
        let invalid = 0;
        Object.entries(this.props.module._infoAnnuelles).map(el => {
            let elementSchema = el[1];
            let valueToAdd = 0;
            for (let i = 0; i < 5; i++) {
                if (elementSchema[i]._statut === 3 && valueToAdd < 1) {
                    valueToAdd = 1;
                }
                if (elementSchema[i]._statut === 1 && valueToAdd < 1.5) {
                    valueToAdd = 1.5;
                }
                if (elementSchema[i]._statut === 2 && valueToAdd < 2) {
                    valueToAdd = 2;
                }
                if (elementSchema[i]._statut === 4 && valueToAdd < 3) {
                    valueToAdd = 3;
                }
            }
            switch (valueToAdd) {
                case 1:
                    valid++;
                    break;
                case 2:
                    warning++;
                    break;
                case 3:
                    invalid++;
                    break;
                default:
            }
            return true;
        });
        this.setState({ valid: valid });
        this.setState({ warning: warning });
        this.setState({ invalid: invalid });
    }

    setModuleStatus(status) {
        let list = {
            'missing data': 'info',
            warning: 'warning',
            valid: 'success',
            invalid: 'danger'
        };

        this.setState({ moduleStatus: list[status] });
    }

    setDescription(id) {
        let list = {
            1: '_modulePL_desc',
            2: '_moduleBilan_desc',
            3: '_moduleChargePersonnel_desc',
            4: '_moduleBFR_desc',
            5: '_modulePlanDeTresorerie_desc',
            6: '_moduleLeveeDeFonds_desc',
            7: '_moduleFinancement_desc'
        };

        this.setState({ description: list[id], isLoading: false });
    }

    render() {
        if (this.state.isLoading) {
            return <p>Loading ...</p>;
        } else {
            const { module, name, uuid, description, moduleStatus, invalid, valid, warning } = this.state;

            return (
                <React.Fragment>
                    <div className='border p-3 rounded mt-4'>
                        <div className='d-flex align-items-center mb-3'>
                            <div className='avatar-xs mr-3'>
                                <span className='avatar-title rounded-circle bg-soft-warning text-warning font-size-18'>
                                    <i className='mdi mdi-view-dashboard'></i>
                                </span>
                            </div>
                            <h5 className='font-size-14 mb-0'>{this.props.t(name)}</h5>
                        </div>

                        <Row>
                            <div className='col-lg-6'>
                                <span className={`badge badge-soft-${moduleStatus}`}>
                                    Module {this.props.t(module._moduleStatus._name)}
                                </span>
                            </div>
                            <div className='col-lg-12'>
                                <div className='text-muted mt-3'>
                                    <p>{this.props.t(description)}</p>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className='col-lg-12'>
                                <span className={`badge badge-soft-success-lg`}>
                                    {valid <= 1 ? `${valid} donnée valide` : `${valid} données valides`}
                                </span>
                            </div>
                            <div className='col-lg-12'>
                                <span className={`badge badge-soft-warning-lg`}>
                                    {warning <= 1
                                        ? `${warning} donnée à surveiller`
                                        : `${warning} données à surveiller`}
                                </span>
                            </div>
                            <div className='col-lg-12'>
                                <span className={`badge badge-soft-danger-lg`}>
                                    {invalid <= 1 ? `${invalid} donnée invalide` : `${invalid} données invalides`}
                                </span>
                            </div>
                        </Row>
                        <Row>
                            <div className='col-lg-6 align-self-end'>
                                <div className='float-right mt-3'>
                                    <Link
                                        className='btn btn-primary'
                                        to={{
                                            pathname: '/business-plan/module/' + module._id,
                                            state: { module: module, uuid: uuid, name: name }
                                        }}
                                    >
                                        {this.props.t('view')}
                                    </Link>
                                </div>
                            </div>
                        </Row>
                    </div>
                </React.Fragment>
            );
        }
    }
}

export default withNamespaces()(rowListBP);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    // Alert,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardText,
    CardSubtitle,
    Col,
    FormGroup,
    UncontrolledCollapse,
    Container,
    Row,
    Label,
    Input,
    Spinner
} from 'reactstrap';
// import classnames from 'classnames';
import { Link, Redirect } from 'react-router-dom';
// import { Redirect } from 'react-router-dom';

import { axiosInstance } from '../../helpers/axios';

//Import Breadcrumb
import Breadcrumbs from '../../components/ThemeCMBP/Partials/Breadcrumb';

//i18n
import { withNamespaces } from 'react-i18next';

class viewModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bp: null,
            module: null,
            isLoading: true,
            errorMessage: null,
            redirectPrev: false,
            info: null,
            warning: 0,
            valid: 0,
            invalid: 0,
            data: null,
            saved: false,
            modulesId: [],
            typeInvestisseur: [],
            names: null,
            description: null,
            redirectOK: false,
            redirectNOK: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const {
            match: { params }
        } = this.props;
        let modules = [];
        let names = [];
        let ct = 0;
        this.setState({ saved: false });
        axiosInstance
            .get(process.env.REACT_APP_API_URL + '/user/me')
            .then(res => {
                axiosInstance
                    .get(process.env.REACT_APP_API_URL + '/module/' + params.id + '/business-plan/parsed')
                    .then(res => {
                        this.setState({ bp: res.data });
                        res.data._modules.forEach(element => {
                            ct++;
                            if (res.data[element]._id === parseInt(params.id)) {
                                this.setState({ module: res.data[element] });
                                this.setDescription(res.data[element]._moduleType._id);
                                this.checkData(res.data[element]);
                            }
                            if (ct === Object.entries(res.data._modules).length) {
                                Object.entries(this.state.module._infoAnnuelles).forEach(name => {
                                    names.push(name[0]);
                                    this.setStateFromForm(name);
                                });
                                modules.push(res.data[element]._id);
                                this.setState({
                                    info: res.data[element]._infoAnnuelles,
                                    modulesId: modules,
                                    names: names,
                                    isLoading: false
                                });
                            }
                        });
                        if (!this.state.modulesId.includes(parseInt(params.id))) {
                            this.setState({ redirectPrev: true });
                        }
                    })
                    .catch(err => {
                        this.setState({ errorMessage: err.message, redirectPrev: true });
                    });
                axiosInstance
                    .get(process.env.REACT_APP_API_URL + '/module/investisseurs')
                    .then(res => {
                        this.setState({ typeInvestisseur: res.data });
                    })
                    .catch(err => {
                        this.setState({ errorMessage: err.message, redirectPrev: true });
                    });
            })
            .catch(err => {
                this.setState({ errorMessage: err.message });
            });
    }

    setDescription(id) {
        let list = {
            1: '_modulePL_desc',
            2: '_moduleBilan_desc',
            3: '_moduleChargePersonnel_desc',
            4: '_moduleBFR_desc',
            5: '_modulePlanDeTresorerie_desc',
            6: '_moduleLeveeDeFonds_desc',
            7: '_moduleFinancement_desc'
        };

        this.setState({ description: list[id] });
    }

    updateData(data) {
        return axiosInstance
            .put(process.env.REACT_APP_API_URL + '/module/' + this.state.module._id, data)
            .then(res => {
                if (res.status === 200) {
                    this.setState({ saved: true, redirectOK: true });
                } else {
                    this.setState({ redirectNOK: true });
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    setStateFromForm(el) {
        for (let i = 0; i < 5; i++) {
            this.setState({ [i + el[0]]: el[1][i]['_value'] });
        }
    }

    checkData(element) {
        // eslint-disable-next-line
        Object.entries(element._infoAnnuelles).map(el => {
            let elementSchema = el[1];

            for (let i = 0; i < 5; i++) {
                if (elementSchema[i]._statut === 2) {
                    this.setState({ warning: this.state.warning + 1 });
                } else if (elementSchema[i]._statut === 3) {
                    this.setState({ valid: this.state.valid + 1 });
                } else if (elementSchema[i]._statut === 4) {
                    this.setState({ invalid: this.state.invalid + 1 });
                }
            }
        });
    }

    handleChange(e) {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();

        let data = { infoAnnuelles: [{}, {}, {}, {}, {}] };

        this.state.names.forEach(element => {
            let elementName = element.substring(1);

            for (let i = 0; i < 5; i++) {
                data['infoAnnuelles'][i] = {
                    ...data['infoAnnuelles'][i],
                    annee: i,
                    [elementName]: this.state[i + element] === '' ? null : this.state[i + element]
                };
            }
        });
        this.setState({ data: data });
        this.updateData(data);
    }

    successCallback(warning, valid, invalid) {
        this.setState({ warning: warning, valid: valid, invalid: invalid });
    }

    prepareForm(e) {
        let elementName = e[0];
        let elementSchema = e[1];
        let classStatut = ['', '', '', '', ''];
        let icon = null;

        for (let i = 0; i < 5; i++) {
            if (elementSchema[i]._statut === 1) {
                classStatut[i] = 'missing data';
            } else if (elementSchema[i]._statut === 2) {
                classStatut[i] = 'warning';
            } else if (elementSchema[i]._statut === 3) {
                classStatut[i] = 'valid';
            } else if (elementSchema[i]._statut === 4) {
                classStatut[i] = 'invalid';
            } else if (elementSchema[i]._statut === 5) {
                classStatut[i] = 'init';
            } else if (elementSchema[i]._statut === 6) {
                classStatut[i] = 'working';
            }
        }

        if (classStatut.includes('invalid')) {
            icon = <i className={`fas fa-times-circle mr-3 radio-status-4`} />;
        } else if (classStatut.includes('warning')) {
            icon = <i className={`fas fa-exclamation-circle mr-3 radio-status-2`} />;
        } else if (classStatut.includes('missing data')) {
            icon = <i className={`fas fa-question-circle m-1 radio-status-1`} />;
        } else if (classStatut.includes('valid')) {
            icon = <i className={`fas fa-check-circle mr-3 radio-status-3`} />;
        } else if (classStatut.includes('init')) {
            icon = <i className={`fas fa-question-circle m-1 radio-status-1`} />;
        }

        return (
            <div className='moduleRow'>
                <h5>
                    {icon}
                    {this.props.t(elementSchema[0]._name)}
                </h5>
                <Col lg={10}>
                    <Label for='elementName'>{this.props.t(elementSchema[0]._legende)}</Label>
                </Col>
                <Row>
                    {elementSchema.map((item, i) =>
                        elementSchema[i]._type === 'boolean' ? (
                            <Col lg={2}>
                                <FormGroup>
                                    {elementSchema[i]._hidden || !elementSchema[i]._editable ? (
                                        <Input
                                            className={classStatut[i]}
                                            type='select'
                                            value={this.state[i + elementName]}
                                            name={'0' + elementName}
                                            onChange={this.handleChange}
                                            disabled
                                        >
                                            <option value='null'>-</option>
                                            <option value='true'>Oui</option>
                                            <option value='false'>Non</option>
                                        </Input>
                                    ) : (
                                        <Input
                                            className={classStatut[i]}
                                            type='select'
                                            value={this.state[i + elementName]}
                                            name={i + elementName}
                                            onChange={this.handleChange}
                                        >
                                            <option value='null'>{`Année N+` + i}</option>
                                            <option value='1'>Oui</option>
                                            <option value='0'>Non</option>
                                        </Input>
                                    )}
                                </FormGroup>
                            </Col>
                        ) : elementSchema[i]._type === 'percent' ? (
                            <Col lg={2}>
                                {elementSchema[i]._hidden || !elementSchema[i]._editable ? (
                                    <Input
                                        className={classStatut[i]}
                                        name={i + elementName}
                                        type='text'
                                        label={this.props.t(elementSchema[i]._legende)}
                                        options={elementSchema[i]._options}
                                        value={
                                            this.state[i + elementName] !== null
                                                ? this.state[i + elementName] + '%'
                                                : '-'
                                        }
                                        placeholder={'-'}
                                        disabled
                                        onChange={this.handleChange}
                                    />
                                ) : (
                                    <Input
                                        className={classStatut[i]}
                                        name={i + elementName}
                                        type='text'
                                        label={this.props.t(elementSchema[i]._legende)}
                                        options={elementSchema[i]._options}
                                        value={
                                            this.state[i + elementName] !== null
                                                ? this.state[i + elementName] + '%'
                                                : '-'
                                        }
                                        placeholder={`Annee N+` + i}
                                        onChange={this.handleChange}
                                    />
                                )}
                            </Col>
                        ) : elementSchema[i]._type === 'investisseur' ? (
                            <Col lg={2}>
                                {elementSchema[i]._hidden || !elementSchema[i]._editable ? (
                                    <Input
                                        className={classStatut[i]}
                                        type='select'
                                        value={this.state[i + elementName]}
                                        name={i + elementName}
                                        placeholder={'-'}
                                        disabled
                                        onChange={this.handleChange}
                                    >
                                        <option value='null'>-</option>
                                        {this.state.typeInvestisseur.map((element, i) => {
                                            return <option value={element._id}>{element._name}</option>;
                                        })}
                                    </Input>
                                ) : (
                                    <Input
                                        className={classStatut[i]}
                                        type='select'
                                        value={this.state[i + elementName]}
                                        name={i + elementName}
                                        onChange={this.handleChange}
                                    >
                                        <option value='null'>{`Année N+` + i}</option>
                                        {this.state.typeInvestisseur.map((element, i) => {
                                            return <option value={element._id}>{element._name}</option>;
                                        })}
                                    </Input>
                                )}
                            </Col>
                        ) : elementSchema[i]._type === 'negative' ? (
                            <Col lg={2}>
                                {elementSchema[i]._hidden || !elementSchema[i]._editable ? (
                                    <Input
                                        className={classStatut[i]}
                                        name={i + elementName}
                                        type='number'
                                        label={this.props.t(elementSchema[i]._legende)}
                                        options={elementSchema[i]._options}
                                        value={this.state[i + elementName]}
                                        placeholder={'-'}
                                        disabled
                                        onChange={this.handleChange}
                                    />
                                ) : (
                                    <Input
                                        className={classStatut[i]}
                                        name={i + elementName}
                                        type='number'
                                        label={this.props.t(elementSchema[i]._legende)}
                                        options={elementSchema[i]._options}
                                        value={this.state[i + elementName]}
                                        placeholder={`Annee N+` + i}
                                        onChange={this.handleChange}
                                    />
                                )}
                            </Col>
                        ) : (
                            <Col lg={2}>
                                {elementSchema[i]._hidden || !elementSchema[i]._editable ? (
                                    <Input
                                        className={classStatut[i]}
                                        name={i + elementName}
                                        type={elementSchema[i]._type}
                                        label={this.props.t(elementSchema[i]._legende)}
                                        options={elementSchema[i]._options}
                                        value={this.state[i + elementName]}
                                        placeholder={'-'}
                                        disabled
                                        min='0'
                                        onChange={this.handleChange}
                                    />
                                ) : (
                                    <Input
                                        className={classStatut[i]}
                                        name={i + elementName}
                                        type={elementSchema[i]._type}
                                        label={this.props.t(elementSchema[i]._legende)}
                                        options={elementSchema[i]._options}
                                        value={this.state[i + elementName]}
                                        placeholder={`Annee N+` + i}
                                        min='0'
                                        onChange={this.handleChange}
                                    />
                                )}
                            </Col>
                        )
                    )}
                    {classStatut.includes('invalid') || classStatut.includes('warning') ? (
                        <Col lg={2}>
                            <Button id={`${elementSchema[0]._help}Invalid`} color='danger' block>
                                Piste d'amélioration
                            </Button>
                            <Button id={elementSchema[0]._help} color='info' block>
                                Aide cohérence
                            </Button>
                        </Col>
                    ) : (
                        <Col lg={2}>
                            <Button id={`${elementSchema[0]._help}Invalid`} hidden>
                                Piste d'amélioration
                            </Button>
                            <Button id={elementSchema[0]._help} color='info' block>
                                Aide cohérence
                            </Button>
                        </Col>
                    )}
                    <Col lg={12} className='helpMessage'>
                        <UncontrolledCollapse toggler={`#${elementSchema[0]._help}Invalid`}>
                            <Card outline color='danger' className='border'>
                                <CardHeader className='bg-transparent'>
                                    <h5 className='my-0 text-danger'>
                                        <i className='mdi mdi-head-question-outline mr-3'></i>Piste d'amélioration
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    {this.props
                                        .t(`${elementSchema[0]._help}Invalid`)
                                        .split('\n')
                                        .map(line => (
                                            <span>
                                                <p>{line}</p>
                                            </span>
                                        ))}
                                </CardBody>
                            </Card>
                        </UncontrolledCollapse>
                    </Col>
                    <Col lg={12} className='helpMessage'>
                        <UncontrolledCollapse toggler={`#${elementSchema[0]._help}`}>
                            <Card outline color='primary' className='border'>
                                <CardHeader className='bg-transparent'>
                                    <h5 className='my-0 text-primary'>
                                        <i className='mdi mdi-help-circle-outline mr-3'></i>Aide contextuelle
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    {this.props
                                        .t(elementSchema[0]._help)
                                        .split('\n')
                                        .map(line => (
                                            <span>
                                                <p>{line}</p>
                                            </span>
                                        ))}
                                </CardBody>
                            </Card>
                        </UncontrolledCollapse>
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        const { bp, module, valid, warning, invalid, description, redirectOK, redirectNOK } = this.state;

        if (redirectOK) {
            return (
                <Redirect
                    to={{
                        pathname: '/business-plan/view/' + bp._uuid_bp,
                        state: {
                            isMessage: true,
                            messageClass: 'success',
                            message: 'Votre module a bien été enregistré !'
                        }
                    }}
                />
            );
        }

        if (redirectNOK) {
            return (
                <Redirect
                    to={{
                        pathname: '/business-plan/view/' + bp._uuid_bp,
                        state: {
                            isMessage: true,
                            class: 'danger',
                            messageClass: "Votre module rencontré un soucis lors de l'enregistrement !"
                        }
                    }}
                />
            );
        }

        if (this.state.isLoading) {
            return (
                <div>
                    <Spinner color='primary' />
                </div>
            );
        } else {
            return (
                <React.Fragment>
                    <div className='page-content'>
                        <Container fluid>
                            <Breadcrumbs title={this.props.t('Liste des BP')} breadcrumbItem='Votre Business Plan' />
                            <Link
                                to={{
                                    pathname: '/business-plan/view/' + bp._uuid_bp
                                }}
                            >
                                <Button color='primary'>Revenir à la liste des modules</Button>
                            </Link>
                            <br />
                            <br />
                            <Row>
                                <Col lg='12'>
                                    <Card>
                                        <CardBody>
                                            <CardTitle>{module._moduleType._name}</CardTitle>
                                            <CardSubtitle className='mb-3 descriptionModule'>
                                                {this.props.t(description)}
                                            </CardSubtitle>
                                            <br />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <Card color='success' className='text-white-50'>
                                        <CardBody className='validation-card'>
                                            <CardTitle className='mb-4 text-white'>
                                                <i className='fas fa-check-circle mr-3'></i>
                                                {valid === 0
                                                    ? '0 champ de validé'
                                                    : valid > 1
                                                        ? `${valid} champs sont validés`
                                                        : `${valid} champ est validé`}
                                            </CardTitle>
                                            <CardText className='text-white'>
                                                Vous pouvez vous penchez sur les autres champs dès maintenant.
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={4}>
                                    <Card color='warning' className='text-white-50'>
                                        <CardBody className='validation-card'>
                                            <CardTitle className='mb-4 text-white'>
                                                <i className='fas fa-exclamation-circle mr-3'></i>
                                                {warning === 0
                                                    ? '0 champ avec une alerte'
                                                    : warning > 1
                                                        ? `${warning} champs ont une alerte`
                                                        : `${warning} champ a une alerte`}
                                            </CardTitle>
                                            <CardText className='text-white'>
                                                Les champs ayant une alerte sont des champs à prendre en compte mais ils
                                                ne sont pas forcément bloquant pour la suite.
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={4}>
                                    <Card color='danger' className='text-white-50'>
                                        <CardBody className='validation-card'>
                                            <CardTitle className='mb-4 text-white'>
                                                <i className='fas fa-times-circle mr-3'></i>
                                                {invalid === 0
                                                    ? '0 champ invalidé'
                                                    : invalid > 1
                                                        ? `${invalid} champs requièrent votre attention`
                                                        : `${invalid} champ requiert votre attention`}
                                            </CardTitle>
                                            <CardText className='text-white'>
                                                Les champs ayant un signal rouge indiquent qu'il y a un manque de
                                                cohérence avec vos autres valeurs rentrées.
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <form onSubmit={this.handleSubmit}>
                                                <Row>
                                                    <Col lg={2}>
                                                        <b>
                                                            <center>Année N</center>
                                                        </b>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <b>
                                                            <center>Année N+1</center>
                                                        </b>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <b>
                                                            <center>Année N+2</center>
                                                        </b>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <b>
                                                            <center>Année N+3</center>
                                                        </b>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <b>
                                                            <center>Année N+4</center>
                                                        </b>
                                                    </Col>
                                                </Row>

                                                {Object.entries(module._infoAnnuelles).map(name =>
                                                    this.prepareForm(name)
                                                )}
                                                <br />
                                                <Col sm='12' md={{ size: 6, offset: 3 }}>
                                                    <Button type='submit' color='success' size='xs' block>
                                                        Calculer la cohérence du module {module._moduleType._name}
                                                    </Button>
                                                </Col>
                                            </form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }
    }
}

viewModule.propTypes = {
    match: PropTypes.object
};

export default withNamespaces()(viewModule);

import React, { Component } from 'react';
import PDFViewer from 'mgr-pdf-viewer-react';
import file1 from '../../assets/files/vulgarisation_poussee_d_analyse_de_rentabilite.pdf';
import file2 from '../../assets/files/vulgarisation_cr_bilan_bfr_fr_caf_et_treso.pdf';
import file3 from '../../assets/files/vulgarisation_methodes_de_valorisation.pdf';
import file4 from '../../assets/files/vulgarisation_notions_en_anglais.pdf';
import file5 from '../../assets/files/vulgarisation_financement.pdf';

import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

//Import Breadcrumb
import Breadcrumbs from '../../components/ThemeCMBP/Partials/Breadcrumb';

class PageVulgarisation extends Component {
    constructor() {
        super();
        this.state = {
            activeTab: '1'
        };
        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className='page-content'>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title='Aide' breadcrumbItem='FAQ' />

                        <div className='checkout-tabs'>
                            <Row>
                                <Col lg='2'>
                                    <Nav className='flex-column' pills>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: this.state.activeTab === '1'
                                                })}
                                                onClick={() => {
                                                    this.toggleTab('1');
                                                }}
                                            >
                                                <br />
                                                <p className='font-weight-bold mb-4'>
                                                    L'analyse de rentabilité d'un projet pour aller plus loin au-delà de
                                                    la vulgarisation
                                                </p>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: this.state.activeTab === '2'
                                                })}
                                                onClick={() => {
                                                    this.toggleTab('2');
                                                }}
                                            >
                                                <br />
                                                <p className='font-weight-bold mb-4'>
                                                    Compte de résultat, bilan, BFR, trésorerie et CAF
                                                </p>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: this.state.activeTab === '3'
                                                })}
                                                onClick={() => {
                                                    this.toggleTab('3');
                                                }}
                                            >
                                                <br />
                                                <p className='font-weight-bold mb-4'>Méthode de valorisation</p>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: this.state.activeTab === '4'
                                                })}
                                                onClick={() => {
                                                    this.toggleTab('4');
                                                }}
                                            >
                                                <br />
                                                <p className='font-weight-bold mb-4'>Quelques termes en anglais ...</p>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: this.state.activeTab === '5'
                                                })}
                                                onClick={() => {
                                                    this.toggleTab('5');
                                                }}
                                            >
                                                <br />
                                                <p className='font-weight-bold mb-4'>Financement</p>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Col>
                                <Col lg='10'>
                                    <Card>
                                        <CardBody>
                                            <TabContent activeTab={this.state.activeTab}>
                                                <TabPane tabId='1'>
                                                    <PDFViewer
                                                        document={{
                                                            url: file1
                                                        }}
                                                    />
                                                </TabPane>
                                                <TabPane tabId='2'>
                                                    <PDFViewer
                                                        document={{
                                                            url: file2
                                                        }}
                                                    />
                                                </TabPane>
                                                <TabPane tabId='3'>
                                                    <PDFViewer
                                                        document={{
                                                            url: file3
                                                        }}
                                                    />
                                                </TabPane>
                                                <TabPane tabId='4'>
                                                    <PDFViewer
                                                        document={{
                                                            url: file4
                                                        }}
                                                    />
                                                </TabPane>
                                                <TabPane tabId='5'>
                                                    <PDFViewer
                                                        document={{
                                                            url: file5
                                                        }}
                                                    />
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default PageVulgarisation;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

//i18n
import { withNamespaces } from 'react-i18next';

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className='footer'>
                    <Container fluid={true}>
                        <Row>
                            <Col md={6}>{new Date().getFullYear()} © CheckMyBP.</Col>
                            <Col md={6}>
                                <div className='text-sm-right d-none d-sm-block'>
                                    {this.props.t('designAndDevelop')}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withNamespaces()(Footer));

import React, { Component } from 'react';

import { Alert, Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

// Redux
import { connect } from 'react-redux';
import { axiosInstance } from '../../helpers/axios';
import { Redirect } from 'react-router-dom';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

//i18n
import { withNamespaces } from 'react-i18next';

class InitFactureVirement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: null,
            users: null,
            infoFacturations: null,
            choosenIF: null,
            isLoading: true,
            notAuthorized: null,
            usersListColumns: [
                {
                    dataField: '_email',
                    text: 'Email',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: '_login',
                    text: 'Identifiant',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: 'menu',
                    text: 'Action',
                    formatter: (cellContent, user) => (
                        <div className='d-flex gap-3'>
                            <div className='d-flex'>
                                <Button className='btn btn-info ml-5' onClick={() => this.handleChoosenUser(user)}>
                                    Choisir cet utilisateur
                                </Button>
                            </div>
                        </div>
                    )
                }
            ],
            IFListColumns: [
                {
                    dataField: '_nom_societe',
                    text: 'Nom de societe',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: '_civilite',
                    text: 'Civilite',
                    sort: true,
                    formatter: (value, row) => <p>{value[0].toUpperCase() + value.slice(1)}</p>
                },
                {
                    dataField: '_nom',
                    text: 'Nom',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: '_prenom',
                    text: 'Prénom',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: '_num_tva_communautaire',
                    text: 'Numéro de TVA',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: '_adresse',
                    text: 'Adresse',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: '_code_postal',
                    text: 'Code Postal',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: '_ville',
                    text: 'Ville',
                    sort: true,
                    formatter: (value, row) => <p>{value}</p>
                },
                {
                    dataField: 'menu',
                    text: 'Action',
                    formatter: (cellContent, IF) => (
                        <div className='d-flex gap-3'>
                            <div className='d-flex'>
                                <Button
                                    className='btn btn-info ml-5'
                                    onClick={() => this.handleChoosenInfoFacturation(IF)}
                                >
                                    Choisir cette ligne
                                </Button>
                            </div>
                        </div>
                    )
                }
            ]
        };

        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    resetMessage() {
        this.setState({ message: null });
    }

    componentDidMount() {
        this.setState({ message: this.props.location?.state?.message });
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            if (obj.user_info != undefined) {
                var droits = obj.user_info.droits_user;
                console.log(droits);
                if (droits.length != 0) {
                    if (droits.find(x => x._id_droit == 1) != undefined) {
                        axiosInstance
                            .get(process.env.REACT_APP_API_URL + '/user/all')
                            .then(res => {
                                this.setState({ users: res.data, notAuthorized: false, isLoading: false });
                            })
                            .catch(err => {
                                this.setState({ notAuthorized: true });
                                console.log(err);
                            });
                    }
                    else
                        this.setState({ notAuthorized: true });
                }
            }
        }
    }

    handleChoosenUser(values) {
        this.resetMessage();
        axiosInstance
            .get(process.env.REACT_APP_API_URL + '/billing/info/user/' + values._id)
            .then(res => {
                // eslint-disable-next-line no-constant-condition
                if (typeof res.data === 'object') {
                    this.setState({ infoFacturations: res.data });
                } else {
                    this.setState({ infoFacturations: [] });
                }
            })
            .catch(err => {
                this.setState({ notAuthorized: true });
                console.log(err);
            });
    }

    handleChoosenInfoFacturation(values) {
        this.resetMessage();
        this.setState({ choosenIF: values });
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.resetMessage();
        axiosInstance
            .post(process.env.REACT_APP_API_URL + '/payment/init/virement', {
                nb_key: values.nbCle,
                remise: values.remise,
                id_user: this.state.choosenIF._id_user,
                id_facturation: this.state.choosenIF._id
            })
            .then(res => {
                if (res.status === 201) {
                    this.setState({
                        infoFacturations: null,
                        choosenIF: null,
                        message: 'Le paiement et la facture ont correctement été initiés'
                    });
                }
            })
            .catch(err => {
                this.setState({ notAuthorized: true });
                console.log(err);
            });
    }

    render() {
        console.log(this.state);
        if (this.state.notAuthorized) {
            return (
                <Redirect
                    to={{
                        pathname: '/logout'
                    }}
                />
            );
        } else if (this.state.isLoading) {
            return <p>Loading ...</p>;
        } else {
            const { users, infoFacturations } = this.state;
            const { SearchBar } = Search;

            const pageOptions = {
                sizePerPage: 10,
                totalSize: users.length,
                custom: true
            };

            const defaultSorted = [
                {
                    dataField: 'id',
                    order: 'desc'
                }
            ];

            return (
                <React.Fragment>
                    <div className='page-content'>
                        <Container fluid>
                            <Row>
                                <Col className='col-12'>
                                    {this.state.message ? <Alert color='success'>{this.state.message}</Alert> : null}
                                    {(this.state.infoFacturations !== null || this.state.choosenIF !== null) && (
                                        <Button
                                            className='btn btn-primary mb-4'
                                            onClick={() => this.setState({ infoFacturations: null, choosenIF: null })}
                                        >
                                            {' '}
                                            Retour à la liste des utilisateurs
                                        </Button>
                                    )}
                                    {this.state.users &&
                                        this.state.infoFacturations === null &&
                                        this.state.choosenIF === null && (
                                            <Card>
                                                <CardBody>
                                                    <h3>Liste des utilisateurs</h3>
                                                    <br />
                                                    <PaginationProvider
                                                        pagination={paginationFactory(pageOptions)}
                                                        keyField='id'
                                                        columns={this.state.usersListColumns}
                                                        data={users}
                                                    >
                                                        {({ paginationProps, paginationTableProps }) => (
                                                            <ToolkitProvider
                                                                keyField='id'
                                                                columns={this.state.usersListColumns}
                                                                data={users}
                                                                search
                                                            >
                                                                {toolkitprops => (
                                                                    <React.Fragment>
                                                                        <Row className='mb-2'>
                                                                            <Col sm='4'>
                                                                                <div className='search-box ms-2 mb-2 d-inline-block'>
                                                                                    <div className='position-relative'>
                                                                                        <SearchBar
                                                                                            placeholder='Rechercher...'
                                                                                            {...toolkitprops.searchProps}
                                                                                        />
                                                                                        <i className='bx bx-search-alt search-icon' />
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col xl='12'>
                                                                                <div className='table-responsive'>
                                                                                    <BootstrapTable
                                                                                        {...toolkitprops.baseProps}
                                                                                        {...paginationTableProps}
                                                                                        defaultSorted={defaultSorted}
                                                                                        classes={
                                                                                            'table align-middle table-nowrap table-hover'
                                                                                        }
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        responsive
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className='align-items-md-center mt-30'>
                                                                            <Col className='pagination pagination-rounded justify-content-end mb-2'>
                                                                                <PaginationListStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                </CardBody>
                                            </Card>
                                        )}
                                    {this.state.users && this.state.infoFacturations && this.state.choosenIF === null && (
                                        <Card>
                                            <CardBody>
                                                <h3>Liste des informations de facturation d'un utilisateur</h3>
                                                <br />
                                                {this.state.infoFacturations.length > 0 ? (
                                                    <PaginationProvider
                                                        pagination={paginationFactory(pageOptions)}
                                                        keyField='id'
                                                        columns={this.state.IFListColumns}
                                                        data={infoFacturations}
                                                    >
                                                        {({ paginationProps, paginationTableProps }) => (
                                                            <ToolkitProvider
                                                                keyField='id'
                                                                columns={this.state.IFListColumns}
                                                                data={infoFacturations}
                                                                search
                                                            >
                                                                {toolkitprops => (
                                                                    <React.Fragment>
                                                                        <Row className='mb-2'>
                                                                            <Col sm='4'>
                                                                                <div className='search-box ms-2 mb-2 d-inline-block'>
                                                                                    <div className='position-relative'>
                                                                                        <SearchBar
                                                                                            placeholder='Rechercher...'
                                                                                            {...toolkitprops.searchProps}
                                                                                        />
                                                                                        <i className='bx bx-search-alt search-icon' />
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col xl='12'>
                                                                                <div className='table-responsive'>
                                                                                    <BootstrapTable
                                                                                        {...toolkitprops.baseProps}
                                                                                        {...paginationTableProps}
                                                                                        defaultSorted={defaultSorted}
                                                                                        classes={
                                                                                            'table align-middle table-nowrap table-hover'
                                                                                        }
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        responsive
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className='align-items-md-center mt-30'>
                                                                            <Col className='pagination pagination-rounded justify-content-end mb-2'>
                                                                                <PaginationListStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                ) : (
                                                    <p>
                                                        Cet utilisateur n’a pas saisi d’informations de facturation, il
                                                        n’est donc pas possible de lui éditer une facture
                                                    </p>
                                                )}
                                            </CardBody>
                                        </Card>
                                    )}
                                    {this.state.users && this.state.infoFacturations && this.state.choosenIF && (
                                        <Card>
                                            <CardBody>
                                                <h3>Création du paiement et attribution des clés</h3>
                                                <br />
                                                {this.state.choosenIF && (
                                                    <>
                                                        <b>{this.state.choosenIF._nom_societe}</b>
                                                        <br />
                                                        <br />
                                                        <AvForm
                                                            className='form-horizontal'
                                                            onValidSubmit={this.handleValidSubmit}
                                                        >
                                                            <div className='form-group'>
                                                                <AvField
                                                                    name='nbCle'
                                                                    label='Nombre de clés'
                                                                    value=''
                                                                    className='form-control'
                                                                    placeholder='Choississez votre nombre de clés'
                                                                    errorMessage='Champ invalide'
                                                                    validate={{
                                                                        number: {
                                                                            value: true,
                                                                            errorMessage:
                                                                                "Ce champ n'accepte que des caractères numériques"
                                                                        },
                                                                        min: {
                                                                            value: 1,
                                                                            errorMessage:
                                                                                'Vous devez au minimim acheter une clé'
                                                                        },
                                                                        pattern: {
                                                                            value: '^[0-9]+$',
                                                                            errorMessage:
                                                                                "Ce champ n'accepte que des caractères numériques"
                                                                        }
                                                                    }}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className='form-group'>
                                                                <AvField
                                                                    name='remise'
                                                                    label='Remise'
                                                                    value='0'
                                                                    className='form-control'
                                                                    placeholder='Choississez la remise en %'
                                                                    errorMessage='Champ invalide'
                                                                    validate={{
                                                                        number: {
                                                                            value: true,
                                                                            errorMessage:
                                                                                "Ce champ n'accepte que des caractères numériques"
                                                                        },
                                                                        min: {
                                                                            value: 0,
                                                                            errorMessage:
                                                                                'La remise ne peux pas être inférieur à 0%'
                                                                        },
                                                                        max: {
                                                                            value: 100,
                                                                            errorMessage: 'Vous ne pouvez pas avoir plus de 100% de remise'
                                                                        },
                                                                        pattern: {
                                                                            value: '^[0-9]+$',
                                                                            errorMessage:
                                                                                "Ce champ n'accepte que des caractères numériques"
                                                                        }
                                                                    }}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className='mt-3'>
                                                                <button
                                                                    className='btn btn-primary btn-block waves-effect waves-light'
                                                                    type='submit'
                                                                >
                                                                    Initier un paiement et une facture par virement pour{' '}
                                                                    {this.state.choosenIF._nom_societe}
                                                                </button>
                                                            </div>
                                                        </AvForm>
                                                        <br />
                                                    </>
                                                )}
                                            </CardBody>
                                        </Card>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            );
        }
    }
}

const mapStatetoProps = () => { };

export default connect(mapStatetoProps)(withNamespaces()(InitFactureVirement));

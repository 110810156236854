import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

// Import menuDropdown
// import LanguageDropdown from '../TopBar/LanguageDropdown';
// import NotificationDropdown from '../TopBar/NotificationDropdown';
import ProfileMenu from '../TopBar/ProfileMenu';

import logo from '../../../assets/images/logo-sm-light.png';
import logoCMBP from '../../../assets/images/logo-cmbp.png';
import logoLightSvg from '../../../assets/images/logo-light.svg';
import logoDark from '../../../assets/images/logo-dark.png';

//i18n
import { withNamespaces } from 'react-i18next';

class Header extends Component {
    constructor(props) {
        super(props);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    /**
     * Toggle sidebar
     */
    toggleMenu() {
        this.props.openLeftMenuCallBack();
    }

    render() {
        return (
            <React.Fragment>
                <header id='page-topbar'>
                    <div className='navbar-header'>
                        <div className='d-flex'>
                            <div className='navbar-brand-box'>
                                <Link to='/' className='logo logo-dark'>
                                    <span className='logo-sm'>
                                        <img src={logo} alt='' height='22' />
                                    </span>
                                    <span className='logo-lg'>
                                        <img src={logoDark} alt='' height='17' />
                                    </span>
                                </Link>

                                <Link to='/' className='logo logo-light'>
                                    <span className='logo-sm'>
                                        <img src={logoLightSvg} alt='' height='22' />
                                    </span>
                                    <span className='logo-lg'>
                                        <img src={logoCMBP} alt='' height='19' />
                                    </span>
                                </Link>
                            </div>

                            <button
                                type='button'
                                className='btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light'
                                data-toggle='collapse'
                                onClick={this.toggleMenu}
                                data-target='#topnav-menu-content'
                            >
                                <i className='fa fa-fw fa-bars'></i>
                            </button>
                        </div>

                        <div className='d-flex'>
                            {/* <LanguageDropdown /> */}

                            {/* <NotificationDropdown /> */}

                            <ProfileMenu />
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withNamespaces()(Header));

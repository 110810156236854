import React, { Component } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';

// Import Routes
import { authProtectedRoutes, publicRoutes } from './routes/';
import AppRoute from './routes/route';

// layouts
import ThemeCMBP from './components/ThemeCMBP/';
import NonAuthLayout from './components/NonAuthLayout';

import MetaTags from 'react-meta-tags';

// Import scss
import './assets/scss/theme.scss';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.getLayout = this.getLayout.bind(this);
    }

    /**
     * Returns the layout
     */
    getLayout = () => {
        return ThemeCMBP;
    };

    render() {
        const Layout = this.getLayout();

        return (
            <React.Fragment>
                <MetaTags>
                    <meta
                        name='description'
                        content='CheckMyBP a pour objectif d’aider les porteurs de projet, créateurs ou dirigeants d’entreprises innovantes à obtenir, facilement et rapidement, une visibilité sur la cohérence de leurs prévisions financières, sans pour autant être expert en finance.'
                    />
                </MetaTags>
                <Router>
                    <Switch>
                        {publicRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                layout={NonAuthLayout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={false}
                            />
                        ))}

                        {authProtectedRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                layout={Layout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={true}
                            />
                        ))}
                    </Switch>
                </Router>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        layout: state.Layout
    };
};

export default connect(mapStateToProps, null)(App);
